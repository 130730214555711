<template>
    <div class="modal fixed z-75 inset-0" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div @click="close" class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" style="transform: translateY(-100px);" aria-hidden="true"></div>
        <div class="flex min-h-screen">
            <div class="inline-block align-bottom bg-white lg:rounded-t-lg text-left shadow-xl transform transition-all w-full mt-auto">
                <div class="max-w-8xl mx-auto px-4 lg:px-8 flex lg:flex-wrap items-center pt-10 pb-4 lg:pb-10 h-screen lg:h-auto flex-col lg:flex-row">
                    <button class="hover:text-primary focus:outline-none ml-4 absolute right-5 top-5" type="button" @click="close" aria-label="Close modal">
                        <icon-x class="h-6"/>
                    </button>
                    <!-- modal content -->
                    <div class="w-full lg:w-auto flex flex-wrap items-center">
                        <div class="flex flex-col mr-4 md:mr-10">
                            <h3 class="text-2xl"><slot name="title"/></h3>
                            <span><slot name="description"/></span>
                        </div>
                    </div>
                    <div class="w-full flex mt-auto lg:w-auto lg:mt-0 ml-auto order-last lg:order-none pt-4 lg:pt-0">
                    </div><!-- END: basic content -->
                    <div class="w-full overflow-y-auto overflow-x-hidden mt-10 lg:max-h-96">
                        <!-- select: Beilagen, Soße,... / optional -->
                        <div v-if="category.availability.available == 'TIMED' " class="mb-10 w-full">
                            <h5 class="mb-2">{{$t('restaurant.category_availability1')}}{{category.title}}{{$t('restaurant.category_availability3')}}</h5>
                            <div class="flex flex-wrap">
                                    <div class="grid gap-2 grid-flow-col grid-cols-7 mt-5 mb-2">
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(1), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(1)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_monday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(2), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(2)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_tuesday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(3), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(3)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_wednesday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(4), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(4)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_thursday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(5), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(5)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_friday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(6), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(6)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_saturday') }}</button>
                                        <button :class="{'bg-primary': category.availability.availabilityTimeRules.availableOn.includes(0), 'bg-gray-500': !(category.availability.availabilityTimeRules.availableOn.includes(0)) }" class="btn inline-flex items-center font-bold rounded-md focus:outline-none text-gray-500 sm transparent justify-center ">{{ $t('dashboard.short_sunday') }}</button>
                                    </div>
                                <div v-for="(timeFrame, index) in category.availability.availabilityTimeRules.timeFrames" :key="index" class="w-full">
                                    <div class="flex flex-wrap items-center w-8 mt-1 mb-1">
                                        <span>{{$t('restaurant.from_time')}}<span class="ml-5 mr-16">{{timeFrame.from[0]}}:{{timeFrame.from[1]}}</span>{{$t('restaurant.to_time')}}<span class="ml-5 mr-5">{{timeFrame.to[0]}}:{{timeFrame.to[1]}}</span></span>
                                    </div>
                                </div><!-- END: basic content -->
                            </div>
                        </div><!-- END select -->
                        <div v-else>
                            <strong>{{$t('restaurant.category_availability1')}}{{category.title}}{{$t('restaurant.category_availability2')}}</strong>
                            <div class="mb-2 text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-8" viewBox="0 0 256 256"><circle cx="128" cy="128" r="96" opacity=".2" fill="currentColor"></circle><circle cx="92" cy="108" r="12" fill="currentColor"></circle><circle cx="164" cy="108" r="12" fill="currentColor"></circle><path d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88z" fill="currentColor"></path><path d="M167.598 160.401a56.028 56.028 0 0 0-88.1 11.591a8 8 0 0 0 13.849 8.013a40.198 40.198 0 0 1 19.086-16.865a39.959 39.959 0 0 1 43.851 8.575a40.175 40.175 0 0 1 6.37 8.29a8 8 0 1 0 13.849-8.012a56.193 56.193 0 0 0-8.905-11.592z" fill="currentColor"></path></svg>
                            </div>
                        </div>
                    </div>
                    <!-- END: modal content -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconX from '@/components/icons/IconX.vue'
import { Category } from 'delivery-boosting-2021-model'

export default {
    data () {
        return {
            readonly: true
        }
    },
    components: { IconX },
    name: 'Modal',
    props: {
        quantity: {
            type: Number
        },
        category: {
            type: Category
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        keyUp (event) {
            if (event.keyCode === 27) {
                this.close()
            }
        }
    },
    created: function () {
        document.addEventListener('keyup', this.keyUp)
    },
    destroyed: function () {
        document.removeEventListener('keyup', this.keyUp)
    }
}
</script>

<style>
    @media screen and (max-width: 1023px) {
        .to-cart.btn.lg {
            @apply px-2 w-full
        }
    }
</style>
