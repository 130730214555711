import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
// import 'firebase/messaging'
import * as model from 'delivery-boosting-2021-model'

/**
 * @typedef {import('delivery-boosting-2021-model/src/controller/DataBaseConnection').DataBaseConnection} DataBaseConnection
 */

const firebaseConfig = {
    apiKey: 'AIzaSyCLCtzhT21PR_5NQMYhQv-jsstMrU7s-8Q',
    authDomain: 'delivery-boosting-2021.firebaseapp.com',
    projectId: 'delivery-boosting-2021',
    storageBucket: 'delivery-boosting-2021.appspot.com',
    // messagingSenderId: '464687358441',
    appId: '1:464687358441:web:e3c5ebcb9a4eebf7eaab78'
}

firebase.initializeApp(firebaseConfig)

firebase.firestore().settings({
    ignoreUndefinedProperties: true
})

global.db = firebase.firestore()
global.functions = firebase.app().functions('europe-west3')

export const FieldValue = firebase.firestore.FieldValue
export const firestore = firebase.firestore
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
/* let messagingApi = {}
try {
    messagingApi = firebase.messaging()
} catch (err) {
    console.warn(err)
}
export const messaging = messagingApi */
export const functions = firebase.functions()
/** @type {DataBaseConnection} */
export const dbc = new model.DataBaseConnection(firebase.firestore)
global.dbc = dbc
global.model = model
global.auth = auth
