<template>
    <div class="flex items-center w-full md:w-1/2">
        <div class="flex items-center flex-grow justify-between">
            <div class="flex items-center w-full relative">
                <div class="flex items-center w-full">
                    <div class="w-full">
                        <input v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :type="inputtype" :name="inputname" :id="inputid" @keyup.enter="$emit('add')" :placeholder="inputplaceholder" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md">
                    </div>
                    <button-b :loading="loading" type="button" class="bg-primary sm mr-3 -ml-28" @click.native="$emit('add')">{{ $t('restaurant.add')}}</button-b>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonB from '../ui/ButtonB.vue'

export default {
    components: { ButtonB },
    props: ['value', 'inputtype', 'inputname', 'inputid', 'inputplaceholder', 'loading']
}
</script>
