<template>
    <card-box class="bg-white rounded-lg">
        <div class="mb-5"><div class="hidden">{{checkAll}}</div>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('restaurant.details') }}</h1>
        </div>
        <div class="">
            <div class="">
                <h4 class="mb-3">{{ $t('restaurant.adress') }}</h4>
                <span class=" font-normal text-base text-gray-500">{{ $t('restaurant.all_with') }}</span><span class="text-red"> *</span><span> {{ $t('restaurant.marked_fields') }}</span>
                <div class="">
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-5">
                        <div class="md:col-span-2 mt-3">
                            <input-normal v-model="$store.state.order.shipping.firstName" inputtype="text" inputname="vorname" inputid="vorname" :inputplaceholder="$t('restaurant.example_name')">
                                <template #title>{{ $t('restaurant.prename') }} <span v-show="checkPreName" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-2 mt-3">
                            <input-normal v-model="$store.state.order.shipping.lastName" inputtype="text" inputname="nachname" inputid="nachname" :inputplaceholder="$t('restaurant.example_surname')">
                                <template #title>{{ $t('restaurant.surname') }} <span v-show="checkSurName" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-3">
                            <input-normal required v-model="$store.state.order.shipping.street" inputtype="text" inputname="straße" inputid="straße" :inputplaceholder="$t('restaurant.example_street')">
                                <template #title>{{ $t('restaurant.street') }} <span v-show="checkStreet" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-1">
                            <input-normal v-model="$store.state.order.shipping.streetNumber" inputtype="text" inputname="hausnr" inputid="hausnr" :inputplaceholder="$t('restaurant.example_number')">
                                <template #title>{{ $t('restaurant.streetnumber') }} <span v-show="checkStreetNumber" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-3">
                            <input-normal required v-model="$store.state.order.shipping.city" inputtype="text" inputname="stadt" inputid="stadt" :inputplaceholder="$t('restaurant.example_city')">
                                <template #title>{{ $t('restaurant.city') }} <span v-show="checkCity" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-1">
                            <input-normal required v-model="$store.state.order.shipping.postalCode" inputtype="text" inputname="plz" inputid="plz" :inputplaceholder="$t('restaurant.example_zip')">
                                <template #title>{{ $t('restaurant.postal_code') }} <span v-show="checkPostalCode" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-2">
                            <input-normal v-model="$store.state.order.shipping.mail" inputtype="email" inputname="email" inputid="email" :inputplaceholder="$t('restaurant.example_mail')">
                                <template #title>{{ $t('restaurant.mail') }} <span v-show="checkMail" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-2">
                            <input-normal v-model="$store.state.order.shipping.phoneNumber" inputtype="tel" inputname="telefonnr" inputid="telefonnr" :inputplaceholder="$t('restaurant.example_telnumber')">
                                <template #title>{{ $t('restaurant.tel') }} <span v-show="checkPhoneNumber" class="text-red">*</span></template>
                            </input-normal>
                        </div>
                        <div class="md:col-span-4">
                            <input-textarea v-model="$store.state.order.extraWish" :inputplaceholder="$t('restaurant.example_extrawish')">
                                <template #title>{{ $t('restaurant.extra_request') }}</template>
                            </input-textarea>
                        </div>
                    </div>
                </div>
                <!--<h4 class="mb-5 mt-10">{{ $t('restaurant.delivery_address') }}</h4>
                <div class="grid grid-cols-1 gap-5">
                    <checkbox checkboxname="Lieferadresse">
                        <span class=" font-normal text-base text-gray-500">{{ $t('restaurant.different_adress') }}</span>
                    </checkbox>
                </div>-->
            </div>
        </div>
    </card-box>
</template>

<script>
import CardBox from '@/components/dashboard/CardBox.vue'
import InputNormal from '@/components/ui/InputNormal.vue'
import InputTextarea from '@/components/ui/InputTextarea.vue'

export default {
    data () {
        return {
            i: true
        }
    },
    components: {
        CardBox,
        InputNormal,
        InputTextarea
    },
    methods: {
    },
    computed: {
        deliveryTime: {
            set (val) {
                this.$store.state.order.deliveryTime = val
            },
            get () {
                return this.$store.state.order.deliveryTime
            }
        },
        now: {
            set (val) {
                this.$store.state.order.timeType.now = val
            },
            get () {
                return this.$store.state.order.timeType.now
            }
        },
        timed: {
            set (val) {
                this.$store.state.order.timeType.timed = val
            },
            get () {
                return this.$store.state.order.timeType.timed
            }
        },
        checkCity () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.city === '') || typeof (this.$store.state.order.shipping.city) === 'undefined') {
                return true
            }
            return false
        },
        checkPostalCode () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.postalCode === '') || typeof (this.$store.state.order.shipping.postalCode) === 'undefined') {
                return true
            }
            return false
        },
        checkStreet () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.street === '') || typeof (this.$store.state.order.shipping.street) === 'undefined') {
                return true
            }
            return false
        },
        checkStreetNumber () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.streetNumber === '') || typeof (this.$store.state.order.shipping.streetNumber) === 'undefined') {
                return true
            }
            return false
        },
        checkPreName () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.firstName === '') || typeof (this.$store.state.order.shipping.firstName) === 'undefined') {
                return true
            }
            return false
        },
        checkSurName () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.lastName === '') || typeof (this.$store.state.order.shipping.lastName) === 'undefined') {
                return true
            }
            return false
        },
        checkMail () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.mail === '') || typeof (this.$store.state.order.shipping.mail) === 'undefined') {
                return true
            }
            return false
        },
        checkPhoneNumber () {
            // eslint-disable-next-line no-unused-expressions
            this.i
            if ((this.$store.state.order.shipping.phoneNumber === '') || typeof (this.$store.state.order.shipping.phoneNumber) === 'undefined') {
                return true
            }
            return false
        },
        // eslint-disable-next-line vue/return-in-computed-property
        checkAll () {
            const city = this.checkCity
            const postalCode = this.checkPostalCode
            const street = this.checkStreet
            const streetNumber = this.checkStreetNumber
            const preName = this.checkPreName
            const surName = this.checkSurName
            const mail = this.checkMail
            const phoneNumber = this.checkPhoneNumber
            if (!city && !postalCode && !street && !streetNumber && !preName && !surName && !mail && !phoneNumber) {
                this.$emit('checkedAll')
                return true
            } else {
                this.$emit('notCheckedAll')
                return false
            }
        }
    },
    created () {
        setInterval(() => { this.i = !this.i; this.$forceUpdate() }, 100)
    }
}
</script>
