<template>
    <div>
        <label class="block text-sm font-bold">
            <slot name="title"/>
        </label>
        <a class="mt-1 border border-gray-200 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center relative overflow-hidden">
            <div @click="reset" class="absolute right-2 top-2 z-11 p-1 cursor-pointer bg-white rounded-md font-bold hover:text-primary">
                <icon-x class="h-4" />
            </div>
            <div class="absolute h-full w-full top-0 left-0 bg-gray-600 z-0" v-if="media.url && media.url != ''" :src="media.url"></div>
            <img class="absolute h-full w-full object-cover top-0 left-0 z-10 opacity-50" v-if="media.url && media.url != ''" :src="media.url">
            <label :for="uuid" class="block space-y-1 text-center z-10" :class="{'text-white': media.url && media.url != ''}">
                <svg class="mx-auto h-12 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <div class="flex text-sm">
                <p class="pr-1">{{$t('dashboard.data_upload')}}</p>
                <label :for="uuid" :class="{'text-primary': media.url && media.url != ''}" class="relative px-2 cursor-pointer bg-white rounded-md font-bold hover:text-primary">
                    <span>{{$t('dashboard.search')}}</span>
                    <input ref="fi" @change="inputChanged" :id="uuid" name="file-upload" type="file" class="sr-only">
                </label>
                </div>
                <p :class="{'text-white': media.url && media.url != '', 'text-gray-500': !media.url}" class="text-xs">
                {{$t('dashboard.data_types')}}
                </p>
            </label>
        </a>
    </div>
</template>

<script>
import { v4 } from 'uuid'
import { storage } from '@/firebase'
import { Media } from 'delivery-boosting-2021-model'
import imageCompression from 'browser-image-compression'
import IconX from '../icons/IconX.vue'

export default {
    components: { IconX },
    name: 'UploadDragAndDrop',
    props: {
        value: {
            type: Media,
            default () {
                return new Media()
            }
        }
    },
    data () {
        return {
            uuid: v4(),
            media: {
                url: (this.value || {}).url,
                alt: (this.value || {}).alt,
                type: (this.value || {}).type,
                origin: (this.value || {}).origin || '',
                author: (this.value || {}).author || '',
                authorUrl: (this.value || {}).authorUrl || ''
            }
        }
    },
    watch: {
        value () {
            const value = this.value || {}
            this.media = {
                url: value.url,
                alt: value.alt,
                type: value.type,
                origin: value.origin,
                author: value.author,
                authorUrl: value.authorUrl
            }
        }
    },
    methods: {
        reset (e) {
            e.preventDefault()
            this.media = {}
            this.$emit('input', new Media(this.media))
        },
        async inputChanged ({ target }) {
            console.log(target)
            const file = target.files[0]
            if (file) {
                const ref = storage.ref().child(`uploads/${this.$route.params.restaurantId}/${v4()}/${file.name}`)
                const img = await imageCompression(file, { maxSizeMB: 0.25 })
                await ref.put(img)
                this.media = {
                    url: await ref.getDownloadURL(),
                    alt: '',
                    type: file.type
                }
                this.$emit('input', new Media(this.media))
            } else {
                this.$emit('input', new Media())
                this.media = {
                    url: '',
                    alt: '',
                    type: ''
                }
            }
        }
    }
}
</script>

<style>
.z-11 {
    z-index: 11;
}
</style>
