<template>
    <span :class="{ selected: state }" class="extra-item px-5 py-2.5 border rounded mb-2 mr-2 cursor-pointer inline-block" @click="toggle"><slot/></span>
</template>

<script>
export default {
    name: 'ExtraButtonSelect',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            state: this.value
        }
    },
    methods: {
        toggle () {
            this.state = !this.state
            this.$emit('input', this.state)
        }
    }
}
</script>

<style>
    .extra-button-select .selected {
        @apply text-primary border-primary;
    }
</style>
