<template>
    <modal2>
        <template #title>{{$t('restaurant.restaurant')}} <span class="text-primary">{{$t('restaurant.not')}} </span>{{$t('restaurant.opened')}}!</template>
        <template #body>
            <span>
                <strong class="text-black">{{$t('restaurant.rest_closed_message')}}</strong>
                <h4 class="mt-5 mb-2">{{$t('restaurant.opening_times')}}</h4>
                <div v-for="(openingHour, index) in getLocation.openingHours" :key="index" class="w-full">
                    <div class="flex items-center w-8 mt-1 mb-1">
                        <div class="flex mr-5">
                        <button class="btn items-center font-bold rounded-md focus:outline-none text-gray-500 bg-primary sm transparent justify-center ">
                                <span v-if="openingHour.day === '1'">{{ $t(`dashboard.short_monday`) }}</span>
                                <span v-if="openingHour.day === '2'">{{ $t(`dashboard.short_tuesday`) }}</span>
                                <span v-if="openingHour.day === '3'">{{ $t(`dashboard.short_wednesday`) }}</span>
                                <span v-if="openingHour.day === '4'">{{ $t(`dashboard.short_thursday`) }}</span>
                                <span v-if="openingHour.day === '5'">{{ $t(`dashboard.short_friday`) }}</span>
                                <span v-if="openingHour.day === '6'">{{ $t(`dashboard.short_saturday`) }}</span>
                                <span v-if="openingHour.day === '0'">{{ $t(`dashboard.short_sunday`) }}</span>
                        </button>
                        </div>
                        <div>
                            {{$t('restaurant.from_time')}}<span class="ml-5 mr-16">{{openingHour.from[0]}}:{{openingHour.from[1]}}</span>{{$t('restaurant.to_time')}}<span class="ml-5 mr-5">{{openingHour.to[0]}}:{{openingHour.to[1]}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!$store.state.realtimedata.open && (getLocation.openingHours || []).length > 0">
                <button-b @click.native="preOrderOn" class="mt-5 bg-primary text-white">Vorbestellen</button-b>
                </div>
            </span>
        </template>
    </modal2>
</template>

<script>
import Modal2 from '../../components/dashboard/Modal2.vue'
import ButtonB from '@/components/ui/ButtonB.vue'

export default {
    data () {
        return {
        }
    },
    components: {
        Modal2,
        ButtonB

    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        getLocation () {
            return this.restaurant.locations.find(l => l.uuid === this.location) || ''
        }
    },
    methods: {
        preOrderOn () {
            if (!this.$store.state.realtimedata.open) {
                this.$emit('preOrderWhenClosed')
            }
        }
    },
    props: {
        location: {
            Type: String
        }
    }
}
</script>
