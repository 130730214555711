<template>
    <a :href="href" :class="settings" class="btn inline-flex items-center font-bold rounded-md shadow-sm text-white focus:outline-none">
        <slot/>
        <slot name="icon"/>
    </a>
</template>

<script>
export default {
    name: 'ButtonA',
    props: ['href', 'settings']
}
</script>
