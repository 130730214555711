<template>
    <div>
        <label :for="inputname" class="block text-sm font-bold"><slot name="title"/></label>
        <div class="relative mt-1">
            <input v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :type="inputtype" :name="inputname" :id="inputid" :placeholder="inputplaceholder" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 rounded-md placeholder-gray-300">
            <slot name="icon" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputNormal',
    props: ['value', 'inputtype', 'inputname', 'inputid', 'inputplaceholder']
}
</script>
