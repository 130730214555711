<template lang="">
    <div class="max-w-8xl mx-auto sm:px-6 lg:px-8">
        <h1>Test</h1>
        <!-- <Button settings="lg bg-primary" href="/">
            test
            <template #icon>
                test
            </template>
        </Button> -->
        <button-a settings="lg bg-primary" href="/">
            Primary Button Large
        </button-a>
        <button-a settings="bg-primary" href="/">
            Primary Button Normal
        </button-a>
        <button-a settings="sm bg-primary" href="/">
            Primary Button Small
        </button-a>
        <badge settings="bg-primary">
            Primary Badge
        </badge>
        <badge settings="bg-primary transparent">
            Primary Badge
        </badge>
        <badge settings="bg-blue">
            Blue Badge
        </badge>
        <badge settings="bg-blue transparent">
            Blue Badge
        </badge>
        <badge settings="bg-yellow">
            Blue Badge
        </badge>
        <badge settings="bg-yellow transparent">
            Blue Badge
        </badge>
        <badge settings="bg-green">
            Blue Badge
        </badge>
        <badge settings="bg-green transparent">
            Blue Badge
        </badge>
        <badge settings="bg-purple">
            Blue Badge
        </badge>
        <badge settings="bg-purple transparent">
            Blue Badge
        </badge>
        <dropdown/>
        <input-normal/>
        <upload-drag-and-drop/>
        <input-textarea/>
    </div>
</template>
<script>
import ButtonA from '@/components/ui/ButtonA.vue'
import Badge from '@/components/ui/Badge.vue'
import Dropdown from '@/components/ui/Dropdown.vue'
import InputNormal from '@/components/ui/InputNormal.vue'
import UploadDragAndDrop from '@/components/ui/UploadDragAndDrop.vue'
import InputTextarea from '@/components/ui/InputTextarea.vue'

export default {
    name: 'UI',
    components: {
        ButtonA,
        Badge,
        Dropdown,
        InputNormal,
        UploadDragAndDrop,
        InputTextarea
    }
}
</script>
