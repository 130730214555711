import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import i18n from './i18n'
import SmoothReflow from './components/util/SmoothReflow'
import VueScrollactive from 'vue-scrollactive'
import VueCurrencyInput from 'vue-currency-input'
import hexRgb from 'hex-rgb'
import { firestorePlugin } from 'vuefire'
import VueChatScroll from 'vue-chat-scroll'
import axios from 'axios'
import AppSection from './app-bridge/AppSection.vue'
import ButtonB from './components/ui/ButtonB.vue'

Vue.use(VueScrollactive)
Vue.use(VueCurrencyInput)
Vue.use(firestorePlugin)
Vue.use(VueChatScroll)

Vue.component('SmoothReflow', SmoothReflow)
Vue.component('AppSection', AppSection)
Vue.component('ButtonB', ButtonB)

Vue.config.productionTip = false

global.store = store
global.axios = axios
// eslint-disable-next-line no-extend-native
Date.prototype.toDate = function () { return new Date(this) }

new Vue({
    router,
    store,
    render: h => h(App),
    i18n,

    created () {
        const root = document.documentElement
        this.$watch(() => this.$route.params.restaurantId, () => this.$store.dispatch('fetchRestaurant'), { immediate: true })
        this.$watch(() => this.$store.state.restaurant.style ? this.$store.state.restaurant.style.color : '', () => {
            const rgb = hexRgb(this.$store.state.restaurant.style.color || '#E3523B')
            root.style.setProperty('--color-primary', `${rgb.red}, ${rgb.green}, ${rgb.blue}`)
            root.style.setProperty('--color-primary-dark', `${Math.max(0, Math.min(rgb.red + 3, 255))}, ${Math.max(0, Math.min(rgb.green - 11, 255))}, ${Math.max(0, Math.min(rgb.blue - 13, 255))}`)
            root.style.setProperty('--color-primary-light', `${Math.max(0, Math.min(rgb.red + 23, 255))}, ${Math.max(0, Math.min(rgb.green + 138, 255))}, ${Math.max(0, Math.min(rgb.blue + 156, 255))}`)
            root.style.setProperty('--color-primary', `${rgb.red}, ${rgb.green}, ${rgb.blue}`)
            root.style.setProperty('--color-primary-darker', `${Math.max(0, Math.min(rgb.red + 3, 255))}, ${Math.max(0, Math.min(rgb.green - 80, 255))}, ${Math.max(0, Math.min(rgb.blue - 100, 255))}`)
            root.style.setProperty('--color-primary-darkermini', `${Math.max(0, Math.min(rgb.red + 3, 255))}, ${Math.max(0, Math.min(rgb.green - 60, 255))}, ${Math.max(0, Math.min(rgb.blue - 80, 255))}`)
            root.style.setProperty('--color-primary-bright', `${Math.max(0, Math.min(rgb.red + 23, 255))}, ${Math.max(0, Math.min(rgb.green + 100, 255))}, ${Math.max(0, Math.min(rgb.blue + 100, 255))}`)

            root.style.setProperty('--color-primary-lighterNEW', `${Math.max(0, Math.min(rgb.red - 23, 255))}, ${Math.max(0, Math.min(rgb.green - 59, 255))}, ${Math.max(0, Math.min(rgb.blue - 36, 255))}`)
            root.style.setProperty('--color-primary-darkNEW', `${Math.max(0, Math.min(rgb.red - 44, 255))}, ${Math.max(0, Math.min(rgb.green - 82, 255))}, ${Math.max(0, Math.min(rgb.blue - 59, 255))}`)
            root.style.setProperty('--color-primary-darkerminiNEW', `${Math.max(0, Math.min(rgb.red - 62, 255))}, ${Math.max(0, Math.min(rgb.green - 75, 255))}, ${Math.max(0, Math.min(rgb.blue - 52, 255))}`)
            root.style.setProperty('--color-primary-darkerNEW', `${Math.max(0, Math.min(rgb.red - 82, 255))}, ${Math.max(0, Math.min(rgb.green - 82, 255))}, ${Math.max(0, Math.min(rgb.blue - 59, 255))}`)
            root.style.setProperty('--color-primary-lightNEW', `${Math.max(0, Math.min(rgb.red - 1, 255))}, ${Math.max(0, Math.min(rgb.green - 37, 255))}, ${Math.max(0, Math.min(rgb.blue - 14, 255))}`)
            root.style.setProperty('--color-primary-lesslightNEW', `${Math.max(0, Math.min(rgb.red - 29, 255))}, ${Math.max(0, Math.min(rgb.green - 56, 255))}, ${Math.max(0, Math.min(rgb.blue - 33, 255))}`)
        }, { immediate: true })
    }
}).$mount('#app')
