<template>
<div v-if="availableCategory">
    <div :id="category.uuid" class="sprung"></div>
<category-banner :category="category" :availability="availability" :timed="timed" :deliveryTime="deliveryTime" @showProducts="show"/>
<SmoothReflow>
<product-box @jumpto="$emit('jumpto', $event)" v-show="availability || available" v-for="product in availableProds" :key="product.uuid" :product="product" :category="category" :stateBasic="stateBasic" :stateVegan="stateVegan" :stateVegetarian="stateVegetarian" :stateGlutenFree="stateGlutenFree" :timed="timed" :deliveryTime="deliveryTime"/>
</SmoothReflow>
<span v-show="availableProds.length == 0 && (stateVegetarian || stateVegan || stateGlutenFree || stateBasic)" class="ml-1"><strong>{{$t('restaurant.the_category')}} {{ category.title }} {{$t('restaurant.no_results_for_seleceted_filters')}}</strong></span>
<span v-show="availableProds.length == 0 && !stateVegetarian && !stateVegan && !stateGlutenFree && ! stateBasic" class="ml-1"><strong>{{$t('restaurant.the_category')}} {{ category.title }} {{'enthält aktuell keine Produkte.'}}</strong></span>

</div>
</template>

<script>
import ProductBox from '@/components/restaurant/ProductBox.vue'
import CategoryBanner from '@/components/restaurant/CategoryBanner.vue'
import { Category, RenderedAvailability } from 'delivery-boosting-2021-model'
export default {
    components: {
        ProductBox,
        CategoryBanner
    },
    props: {
        category: {
            type: Category
        },
        stateVegetarian: {
            type: Boolean
        },
        stateVegan: {
            type: Boolean
        },
        stateGlutenFree: {
            type: Boolean
        },
        stateBasic: {
            type: Boolean
        },
        location: {
            type: String
        },
        timed: {
            type: Boolean
        },
        deliveryTime: {
            type: Date
        }
    },
    computed: {
        availableProds () {
            if (this.timed && this.deliveryTime) {
                const timeProds = this.$store.state.catalog.getProducts(this.category.products).filter(p => (RenderedAvailability.checkAvailability(p.availability, new Date(this.deliveryTime)).state) && (!this.stateVegetarian || p.properties.vegetarian || p.properties.vegan) && (!this.stateVegan || p.properties.vegan) && (!this.stateGlutenFree || p.properties.glutenFree) && (!this.stateBasic || p.properties.basic))
                return timeProds.filter(p => p.availability.locations.includes(this.location))
            } else {
                const prods = this.$store.state.catalog.getProducts(this.category.products).filter(p => (RenderedAvailability.checkAvailability(p.availability, new Date()).state) && (!this.stateVegetarian || p.properties.vegetarian || p.properties.vegan) && (!this.stateVegan || p.properties.vegan) && (!this.stateGlutenFree || p.properties.glutenFree) && (!this.stateBasic || p.properties.basic))
                return prods.filter(p => p.availability.locations.includes(this.location))
            }
        },
        available: {
            get () {
                if (this.timed && this.deliveryTime) {
                    return RenderedAvailability.checkAvailability(this.category.availability, new Date(this.deliveryTime)).available
                } else {
                    return RenderedAvailability.checkAvailability(this.category.availability, new Date()).available
                }
            }
        },
        // eslint-disable-next-line vue/return-in-computed-property
        availableCategory () {
            if (this.timed && this.deliveryTime) {
                return RenderedAvailability.checkAvailability(this.category.availability, new Date(this.deliveryTime)).state
            } else {
                return RenderedAvailability.checkAvailability(this.category.availability, new Date()).state
            }
        }
    },
    data () {
        return {
            availability: this.available
        }
    },
    methods: {
        show () {
            if (this.availability) {
                this.availability = false
            } else {
                this.availability = true
            }
        }
    },
    watch: {
        availableCategory: {
            immediate: true,
            handler () {
                if (!this.availableCategory) {
                    this.$emit('notAvailable', this.category)
                }
                if (this.availableCategory) {
                    this.$emit('available', this.category)
                }
            }
        }
    }
}
</script>

<style>
.sprung {visibility: hidden; height: 0px !important; position: absolute; margin: -110px;}
</style>
