<template>
    <div class="relative flex items-start cursor-pointer" >
        <div class="flex items-center h-5 cursor-pointer">
            <input @click="state=!state" :id="`${checkboxname}-${uuid}`" :name="checkboxname" v-model="state" type="checkbox" class="h-5 w-5 text-primary border-gray-300 rounded focus:ring-primary-dark">
        </div>
        <div class="ml-3 text-sm cursor-pointer">
            <label :for="`${checkboxname}-${uuid}`" class="font-bold"><slot/></label>
        </div>
    </div>
</template>

<script>
import { v4 } from 'uuid'

export default {
    props: ['checkboxname', 'value'],
    data () {
        return {
            state: this.value || false,
            uuid: v4()
        }
    },
    watch: {
        state (state) {
            this.$emit('input', state)
        },
        value (value) {
            if (value !== this.state) {
                this.state = value
            }
        }
    }
}
</script>

<style>

</style>
