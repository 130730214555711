<template>
    <modal2>
        <template #title>
            {{ $t('restaurant.order_impossible') }}
        </template>
        <template #body>
            <span class="text-gray-600 mt-3">
                <strong class="text-black">{{ $t('restaurant.high_request')}}</strong>
            </span>
        </template>
    </modal2>
</template>

<script>
import Modal2 from '../../components/dashboard/Modal2.vue'

export default {
    components: {
        Modal2

    }
}
</script>
