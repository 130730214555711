<template>
    <card-box class="bg-white rounded-lg">
        <div class="mb-5">
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('restaurant.payment') }}</h1>
        </div>
        <div class="">
            <div class="">
                <h4 class="mb-5">{{ $t('restaurant.payment_methods') }}</h4>
                <div class="grid grid-cols-2 gap-5">
                    <div class="col-span-2">
                        <div class="grid gap-5" :class="{'sm:grid-cols-2 lg:grid-cols-2': paymentMethods.length === 2, 'sm:grid-cols-1 lg:grid-cols-1': paymentMethods.length === 1, 'sm:grid-cols-3 lg:grid-cols-3': paymentMethods.length === 3, 'sm:grid-cols-4 lg:grid-cols-4': paymentMethods.length === 4}">
                            <!--<button @click="paymentCreditCard" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary text-primary': creditCard}">
                                <icon-credit-card class="max-h-8 mr-2"/> {{$t('restaurant.card')}}
                                <span v-if="creditCard" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>-->
                            <button v-if="paymentMethods.includes('paypal')" @click="paymentPaypal" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary': payPal}">
                                <img class="h-auto w-auto m-auto max-h-6" src="@/assets/images/payment/paypal.svg" :alt="$t('restaurant.paypal')">
                                <span v-if="payPal" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>
                            <!--<button @click="paymentSofort" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary': sofort}">
                                <img class="h-auto w-auto m-auto max-h-8 filter grayscale opacity-70" src="@/assets/images/payment/sofort.svg" :alt="$t('restaurant.now')">
                                <span v-if="sofort" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>-->
                            <button v-if="paymentMethods.includes('cash')" @click="paymentCash" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary text-primary': cash}">
                                <icon-euro class="max-h-8 mr-2"/> {{$t('restaurant.bar')}}
                                <span v-if="cash" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>
                            <button v-if="paymentMethods.includes('localec')" @click="paymentLocalec" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary text-primary': localec}">
                                <icon-credit-card class="max-h-8 mr-2"/> {{$t('restaurant.localec')}}
                                <span v-if="localec" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>
                            <button v-if="paymentMethods.includes('accountid')" @click="paymentAccountid" class="flex items-center justify-center border rounded h-20 font-bold focus:outline-none hover:opacity-80 transition relative" :class="{'border-primary text-primary': accountid}">
                                <icon-euro class="max-h-8 mr-2"/> {{$t('restaurant.accountid')}}
                                <span v-if="accountid" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full"><icon-check class="h-8 text-white"/></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="accountid">
                    <h4 class="mb-5 mt-10">{{ $t('restaurant.accountid') }}</h4>
                    <input-normal required v-model="accountIdValue" inputtype="text" inputname="stadt" inputid="stadt" :inputplaceholder="''">
                        <template #title>{{ $t('restaurant.accountid') }} <span v-show="accountIdValue == ''" class="text-red">*</span></template>
                    </input-normal>
                </div>
                <!--<div class="text-base text-gray-500 mt-5">
                    Lorem kevin dolor zur erinn, erung adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>-->
                <div v-if="$store.state.restaurant.discountActive != true">
                    <h4 class="mb-5 mt-10">{{ $t('restaurant.coupon') }}</h4>
                    <div v-if="$store.state.order.discounts.length === 0" class="text-base text-gray-500 mt-5">
                        {{ $t('restaurant.discount_text') }}
                    </div>
                    <div v-if="$store.state.order.discounts.length > 0" class="text-base text-gray-500 mt-5">
                        {{ $t('restaurant.more_discounts') }}
                    </div>
                    <coupon-input v-model="coupon" :loading="loadingCoupon" inputtype="text" inputname="gutschein" inputid="gutschein" inputplaceholder="" @add="addCoupon"/>
                    <div class="mt-2" v-if="$store.state.order.discounts.length > 0">
                        <div v-for="discount in $store.state.order.discounts" :key="discount.uuid">
                            <svg xmlns="http://www.w3.org/2000/svg" class="text-green h-7 w-7 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <strong >
                                {{'Gutschein "'}}<strong class="text-primary">{{discount.code}}</strong>{{'" erfolgreich hinzugefügt'}}
                            </strong>
                        </div>
                    </div>
                </div>
                <h4 class="mb-5 mt-10">{{ $t('restaurant.overview') }}</h4>
                <div class="mb-10">
                    <div class="grid grid-cols-1">
                        <overview-product v-for="renderedLineItem in $store.getters.renderedOrder.lineItems" :key="renderedLineItem.uuid">
                            <template #amount>
                                {{renderedLineItem.quantity}} x
                            </template>
                            <template #mainproduct>
                                {{ renderedLineItem.product.title }}
                            </template>
                            <template #size v-if="renderedLineItem.product.sizes.length > 1">
                                <badge settings="bg-primary transparent">{{renderedLineItem.size.title}}</badge>
                            </template>
                            <template #addons>
                                <li v-for="selection in renderedLineItem.selections" :key="selection.uuid"><span class="text-primary"><strong>{{selection.selection.title}}:</strong></span> {{selection.option.title}} </li>
                                <li v-for="upgrade in renderedLineItem.upgrades" :key="upgrade.uuid">
                                    <badge settings="bg-gray-600 transparent">
                                        {{ upgrade.title }}
                                    </badge>
                                </li>
                            </template>
                            <template #price>
                                {{ $n(renderedLineItem.price/100, 'currency') }}
                            </template>
                        </overview-product>
                        <div v-if="$store.state.order.discounts.length > 0">
                            <overview-product v-show="($store.state.catalog.getMethodByUuid($store.state.order.method.method)|| {}).priceAmount !== 0">
                                <template #mainproduct>
                                    <span class="flex">Rabatt
                                        <div v-for="discount in $store.state.order.discounts" :key="discount.uuid">
                                            <badge class="ml-2 transparent bg-green">{{discount.code}}</badge>
                                        </div>
                                    </span>
                                    <!--<span class="text-green" v-if="$store.state.order.discounts.length === 1">{{ 'Rabatt (Gutscheincode: ' }} {{codeArrayToString}}</span>
                                    <span class="text-green" v-else>{{ 'Rabatt (Gutscheincodes: ' }} {{codeArrayToString}}</span>-->
                                </template>
                                <template #price>
                                    <span class="text-green">{{ $n(($store.getters.renderedOrder).newDiscountTotal/100, 'currency')}}</span>
                                </template>
                            </overview-product>
                        </div>
                        <div v-if="!maxValueReached || !$store.state.catalog.getMethodByUuid($store.state.order.method.method).maxValue">
                            <overview-product v-show="($store.state.catalog.getMethodByUuid($store.state.order.method.method)|| {}).priceAmount !== 0">
                                <template #mainproduct>
                                    {{ $t(`restaurant.surcharge_${$store.state.order.method.method}`) }}
                                </template>
                                <template #price >
                                    {{ $n(($store.state.catalog.getMethodByUuid($store.state.order.method.method) || {}).priceAmount/100, 'currency')}}
                                </template>
                            </overview-product>
                        </div>
                        <div v-if="$store.state.restaurant.locations.length > 1 && $store.state.order.locations != ''">
                            <overview-product>
                                <template #mainproduct>
                                    <div >{{ $t('restaurant.location:') }} <span class="text-primary">{{ location.generalTitle }}</span></div>
                                </template>
                            </overview-product>
                        </div>
                    </div>
                </div>
                <checkbox v-model="agbAccept" checkboxname="Sofort" class="col-span-1">
                        <span class="cursor-pointer font-normal text-base text-gray-500"> {{ $t('restaurant.privacy_policy_text1') }} <a :href="$store.state.restaurant.footerDatasec" target="_blank" class="text-primary cursor-pointer z-25">{{ $t('restaurant.privacy_policy') }}</a> {{ $t('restaurant.privacy_policy_text2') }} {{$store.state.restaurant.headerTitle}} {{ $t('restaurant.privacy_policy_text3') }}</span>
                </checkbox>
                <div class="flex w-full mt-10">
                    <span v-if="!detailsComplete" class="text-red m-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span class="text-red ml-1">{{ $t('restaurant.unfinished_fields') }}</span>
                    </span>
                    <span v-if="outOfArea" class="text-red m-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span class="text-red ml-1">{{ $t('restaurant.out_of_area') }}</span>
                    </span>
                    <button-b :loading="loading" :disabled="!agbAccept || !detailsComplete" type="button" @click.native="order" :class="{'bg-gray-500 cursor-default pointer-events-none': !agbAccept || !detailsComplete}" class="bg-primary sm ml-auto w-56 h-16 flex justify-center font-semibold">{{ $t('restaurant.order') }} {{ $n($store.getters.renderedOrder.orderPrice/100, 'currency') }}</button-b>
                </div>
            </div>
        </div>
    </card-box>
</template>

<script>
import CardBox from '@/components/dashboard/CardBox.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import CouponInput from '../../components/restaurant/CouponInput.vue'
import ButtonB from '../../components/ui/ButtonB.vue'
import OverviewProduct from '../../components/restaurant/OverviewProduct.vue'
import IconCheck from '../icons/IconCheck.vue'
import IconEuro from '../icons/IconEuro.vue'
import IconCreditCard from '../icons/IconCreditCard.vue'
import Badge from '../ui/Badge.vue'
import InputNormal from '@/components/ui/InputNormal.vue'
import { polygon, point } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import axios from 'axios'

export default {
    data () {
        return {
            agbAccept: false,
            coupon: '',
            orderList: {
                orders: []
            },
            creditCard: false,
            payPal: false,
            sofort: false,
            cash: true,
            localec: false,
            accountid: true,
            loading: false,
            loadingCoupon: false,
            outOfArea: false,
            accountIdValue: ''
        }
    },
    props: {
        detailsComplete: {
            type: Boolean
        },
        maxValueReached: {
            type: Boolean
        },
        location: {
            type: Object
        }
    },
    components: {
        CardBox,
        Checkbox,
        CouponInput,
        ButtonB,
        OverviewProduct,
        IconCheck,
        IconEuro,
        IconCreditCard,
        Badge,
        InputNormal
    },
    computed: {
        catalog () {
            return this.$store.state.catalog
        },
        paymentMethods () {
            return this.$store.state.order.method.method === 'pick-up' ? this.catalog.paymentMethodsPickup : this.catalog.paymentMethodsDelivery
        }
    },
    watch: {
        accountIdValue (val) {
            this.$store.state.order.metafields.accountId = val
        }
    },
    methods: {
        async addCoupon () {
            if (this.coupon !== '') {
                this.loadingCoupon = true
                const discount = await this.$store.state.restaurant.getDiscount(this.coupon)
                if (this.$store.state.order.discounts.map(d => d.code).includes(this.coupon)) {
                    this.loadingCoupon = false
                    setTimeout(this.duplicateCoupon, 150)
                } else if (discount.code === undefined) {
                    this.loadingCoupon = false
                    setTimeout(this.noCoupon, 150)
                } else {
                    this.$store.state.order.addDiscount(discount)
                    this.loadingCoupon = false
                    this.coupon = ''
                }
            }
        },
        noCoupon () {
            alert('Der Code "' + this.coupon + '" ist nicht gültig.')
            this.coupon = ''
        },
        duplicateCoupon () {
            alert('Der Code "' + this.coupon + '" wird bereits benutzt.')
            this.coupon = ''
        },
        removeCoupon (coupon) {
            this.$store.state.order.removeDiscount(coupon)
        },
        async checkInsideArea () {
            if (this.$store.state.order.methodUuid !== 'delivery') {
                return true
            }
            const { street, streetNumber, city, postalCode } = this.$store.state.order.shipping
            const location = this.$store.state.restaurant.locations.find(l => l.uuid === this.$store.state.order.location)
            const { data } = await axios.get('https://nominatim.openstreetmap.org/search.php', {
                params: {
                    q: `${street} ${streetNumber} ${postalCode} ${city}`,
                    format: 'json'
                }
            })
            if (typeof data[0] === 'undefined') {
                return false
            }
            if (location.orderArea && location.orderArea.length > 0) {
                const { lat, lon } = data[0]
                const p = point([parseFloat(lat), parseFloat(lon)])
                const area = location.orderArea.map(p => [p.lat, p.lng])
                const poly = polygon(
                    [
                        area
                    ]
                )
                const inside = booleanPointInPolygon(p, poly)
                return inside
            } else {
                return true
            }
        },
        async order () {
            this.loading = true
            this.$store.state.order.catalog = this.$store.state.catalog.uuid
            if (!(await this.checkInsideArea())) {
                this.loading = false
                this.outOfArea = true
                return
            }
            await this.$store.state.restaurant.createOrder(this.$store.state.order)
            const response = await this.$store.state.restaurant.payOrder(this.$store.state.order.uuid)
            if (response.actionRequired) {
                window.location.href = response.actionURL
            } else {
                this.$router.push({ name: 'order-complete', params: { restaurantId: this.$store.state.restaurant.uuid, orderId: this.$store.getters.renderedOrder.uuid, maxValueReached: this.maxValueReached } })
            }
            this.loading = false
        },
        paymentCreditCard () {
            this.creditCard = true
            this.payPal = false
            this.sofort = false
            this.cash = false
            this.localec = false
            this.accountid = false
            this.$store.state.order.paymentMethod = 'card'
        },
        paymentPaypal () {
            this.payPal = true
            this.sofort = false
            this.cash = false
            this.creditCard = false
            this.localec = false
            this.accountid = false
            this.$store.state.order.paymentMethod = 'paypal'
        },
        paymentSofort () {
            this.sofort = true
            this.cash = false
            this.creditCard = false
            this.payPal = false
            this.localec = false
            this.accountid = false
            this.$store.state.order.paymentMethod = 'sofort'
        },
        paymentCash () {
            this.cash = true
            this.creditCard = false
            this.payPal = false
            this.sofort = false
            this.localec = false
            this.accountid = false
            this.$store.state.order.paymentMethod = 'cash'
        },
        paymentLocalec () {
            this.localec = true
            this.cash = false
            this.creditCard = false
            this.payPal = false
            this.sofort = false
            this.accountid = false
            this.$store.state.order.paymentMethod = 'localec'
        },
        paymentAccountid () {
            this.accountid = true
            this.cash = false
            this.creditCard = false
            this.payPal = false
            this.sofort = false
            this.localec = false
            this.$store.state.order.paymentMethod = 'accountid'
        }

    },
    created () {
        if (this.paymentMethods.includes('cash')) {
            this.paymentCash()
        } else if (this.paymentMethods.includes('paypal')) {
            this.paymentPaypal()
        }
    }
}

</script>
