<template>
    <ExternalComponent :app="renderedApp" :product="product" :category="category" :order="order" />
</template>

<script>
import loader from 'http-vue-loader'
let resolver = () => {}
const loadPromise = new Promise((resolve) => { resolver = resolve })

export default {
    name: 'RemoteSection',
    props: ['url', 'restaurant', 'app', 'product', 'category', 'order'],
    components: {
        ExternalComponent: async function () { return (await loadPromise)() }
    },
    async mounted () {
        this.renderedApp = this.restaurant.getRenderedApp(this.app.package)
        resolver(loader('https://firebasestorage.googleapis.com/v0/b/delivery-boosting-2021-apps/o/' + encodeURIComponent(this.url) + '?alt=media'))
    },
    data () {
        return {
            renderedApp: {}
        }
    }
}
</script>

<style>
</style>
