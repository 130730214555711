<template>
    <div class="grid grid-flow-col grid-cols-8 g-2 mb-6">
        <div class="col-span-1">
            <div class="flex items-center text-gray-600">
                <span class="font-bold mr-1 text-xl">{{lineItem.quantity}}</span>
                <icon-x class="h-5"/>
            </div>
        </div>
        <div class="col-span-4 flex flex-col">
            <h5 class="text-xl mb-1">{{ lineItem.product.title }}<span v-if="lineItem.product.excludefromMinOrder && minOrderSet && lineItem.product.sizes.length <= 1" class="text-red"> *</span></h5>
            <div v-if="lineItem.product.sizes.length > 1" class="mb-2">
                <strong class="text-primary">Größe: </strong><badge settings="bg-gray-600 transparent">{{lineItem.size.title}}</badge><span class="text-red" v-if="minOrderSet && lineItem.product.excludefromMinOrder"> *</span>
                <!-- Optischer Unterschied zwischen verschiedenen Größen
                <badge settings="bg-purple transparent">Klein</badge>
                <badge settings="bg-primary transparent">Normal</badge>
                <badge settings="bg-green transparent">Groß</badge> -->
            </div>
            <ul class="leading-8">
                <li v-for="selection in lineItem.selections" :key="selection.uuid">
                    <span class="text-primary"><strong>{{selection.selection.title}}:</strong></span>
                    {{selection.option.title}} <span v-if="selection.excludefromMinOrder && minOrderSet" class="text-red">*</span>
                </li>
                <li v-for="upgrade in lineItem.upgrades" :key="upgrade.uuid">
                    <badge settings="bg-gray-600 transparent" >
                        {{ upgrade.title }}
                    </badge><span class="text-red" v-if="upgrade.excludefromMinOrder && minOrderSet"> *</span>
                </li>
            </ul>
        </div>
        <div class="col-span-3">
            <div class="flex items-center justify-end">
                <h5 class="text-primary text-xl">{{ $n(lineItem.price/100, 'currency') }}</h5>
                <button @click="remove" class="inline-flex items-center font-bold rounded-md text-gray-500 focus:outline-none text-lg ml-2 hover:text-primary"><icon-x class="w-8"/></button>
            </div>
        </div>
    </div>
</template>

<script>
import IconX from '@/components/icons/IconX.vue'
import { RenderedLineItem } from 'delivery-boosting-2021-model'
import Badge from '@/components/ui/Badge.vue'
export default {
    components: { IconX, Badge },
    props: {
        lineItem: {
            type: RenderedLineItem
        },
        minOrderSet: {
            Type: Boolean
        }
    },
    methods: {
        remove () {
            this.$emit('remove')
        }
    },
    created () {
    }
}
</script>

<style>

</style>
