<template>
    <div class="flex items-center cursor-pointer" :class="settings" @click="state=!state">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <button type="button" :class="{ 'bg-gray-200': !state, 'bg-primary': state }" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" aria-pressed="false">
            <span class="sr-only">Use setting</span>
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span aria-hidden="true" :class="{ 'translate-x-5': state, 'translate-x-0': !state }" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white transform ring-0 transition ease-in-out duration-200"></span>
        </button>
        <span id="annual-billing-label">
            <slot/>
        </span>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    props: ['value', 'settings'],
    data () {
        return {
            state: this.value || false
        }
    },
    watch: {
        state (state) {
            this.$emit('input', state)
        },
        value: {
            immediate: true,
            handler (value) {
                if (value !== this.state) {
                    this.state = value
                }
            }
        }
    }
}
</script>
