<template>
    <div :class="state" class="checkoutnav flex relative p-10 pl-8 justify-content items-center w-full rounded-lg">
        <div class="h-12 w-12"><slot name="icon"></slot></div>
        <h4 class="font-bold flex items-center ml-4"><slot name="title"></slot></h4>
        <div class="number absolute font-bold text-white rounded-full h-10 w-10 flex items-center justify-center -right-5"><slot name="number"></slot></div>
    </div>
</template>

<script>
export default {
    name: 'NavCard',
    props: ['state']
}
</script>

<style>
    .checkoutnav.active {
        @apply border-solid border-2 border-white bg-white;
    }
    .checkoutnav.active h4, .checkoutnav.active svg {
        @apply text-primary;
    }
    .checkoutnav.active .number {
        @apply bg-primary;
    }
    .checkoutnav:not(.active) .number {
        @apply bg-gray-400;
    }
    .checkoutnav:not(.active) {
        @apply border-dashed border-2 border-gray-300;
    }
    .checkoutnav:not(.active) h4 {
        @apply text-gray-500;
    }
    .checkoutnav:not(.active) svg {
        @apply text-gray-500;
    }
</style>
