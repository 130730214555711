<template>
    <div class="body min-h-screen">
        <restaurant-checkout-header />
        <div class="body md:bg-gray-100">
            <div class="max-w-8xl mx-auto px-4 lg:px-8 py-8">
                <div class="flex flex-wrap">
                    <!-- checkout progress -->
                    <div class="hidden lg:block w-2/7 pr-10" style="margin-top:2rem">
                        <div class="flex flex-col sticky z-50" style="top:2rem">
                            <div class="flex flex-col flex-grow pb-4">
                                <div class="flex-grow flex flex-col ">
                                    <nav class="flex-1 space-y-6 ">
                                        <div>
                                            <a href="#details">
                                                <nav-card :state="(currentPos > 0)? 'active' : ''">
                                                    <template #icon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                        </svg>
                                                    </template>
                                                    <template #title>{{ $t('restaurant.details') }}</template>
                                                    <template #number>1</template>
                                                </nav-card>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="#payment">
                                                <nav-card :state="(currentPos <= 0)? 'active' : ''">
                                                <template #icon>
                                                    <icon-credit-card/>
                                                </template>
                                                <template #title>{{ $t('restaurant.payment') }}</template>
                                                <template #number>2</template>
                                                </nav-card>
                                            </a>
                                        </div>
                                        <nav-card>
                                            <template #icon>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                    <path opacity=".3" d="M4 13v1h4v-3H6c-1.1 0-2 .9-2 2z" fill="currentColor"></path><path d="M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7zM7 17c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1zm1-3H4v-1c0-1.1.9-2 2-2h2v3z" fill="currentColor"></path><path d="M5 6h5v2H5zm14 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z" fill="currentColor"></path>
                                                </svg>
                                            </template>
                                            <template #title>{{ $t('restaurant.delivery') }}</template>
                                            <template #number>3</template>
                                        </nav-card>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div><!-- END: checkout progress -->
                    <div class="w-full lg:w-5/7">
                        <main class="grid grid-cols-1 gap-7 focus:outline-none" tabindex="0">
                            <div id="details"></div>
                                <checkout-details @checkedAll="detailsComplete = true" @notCheckedAll="detailsComplete = false"/>
                            <div id="payment" ref="paymark"></div>
                                <checkout-payment :detailsComplete="detailsComplete" :maxValueReached="maxValueReached" :location="location"/>
                        </main>
                    </div>
                </div>
            </div>
        </div>
        <restaurant-footer/>
    </div>
</template>

<script>

import RestaurantFooter from '@/components/layout/RestaurantFooter.vue'
import NavCard from '@/components/restaurant/NavCard.vue'
import RestaurantCheckoutHeader from '@/components/layout/RestaurantCheckoutHeader.vue'
import IconCreditCard from '../../components/icons/IconCreditCard.vue'
import CheckoutDetails from '@/components/restaurant/CheckoutDetails.vue'
import CheckoutPayment from '@/components/restaurant/CheckoutPayment.vue'

export default {
    name: 'OrderCheckout',
    data () {
        return {
            isModalVisible: false,
            value: null,
            detailsComplete: false,
            currentPos: ''
        }
    },
    props: {
        maxValueReached: {
            type: Boolean
        },
        location: {
            type: Object
        }
    },
    methods: {
        updateScroll (e) {
            this.currentPos = this.$refs.paymark.getBoundingClientRect().y
        }
    },
    mounted () {
        this.updateScroll()
        document.addEventListener('scroll', this.updateScroll)
    },
    destroyed () {
        document.removeEventListener('scroll', this.updateScroll)
    },
    components: {
        RestaurantFooter,
        NavCard,
        RestaurantCheckoutHeader,
        IconCreditCard,
        CheckoutDetails,
        CheckoutPayment
    },
    created () {
        window.scrollTo({
            top: 0,
            left: 100,
            behaviour: 'auto'
        })
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
