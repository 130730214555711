<template>
    <div>
        <label for="about" class="block text-sm font-bold">
            <slot name="title"/>
        </label>
        <div class="mt-1">
            <textarea v-bind:value="value" v-on:input="$emit('input', $event.target.value)" :name="inputname" :id="inputid" :placeholder="inputplaceholder"  rows="5" class="focus:ring-primary focus:border-primary block w-full p-3.5 border-gray-200 placeholder-gray-300 rounded-md"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputTextarea',
    props: ['value', 'inputname', 'inputid', 'inputplaceholder']
}
</script>
