<template>
    <div>
      <div class="fixed z-10 bg-gray-600 bg-opacity-75 transition-opacity" style="width: 200vw;height: 200vh;top:-50vh;left:-50vw;pointer-events: none;" aria-hidden="true"></div>
      <div class="modal fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block sm:p-0">
          <div class="inline-block align-bottom bg-white rounded-t-lg sm:rounded-lg text-left shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-200 sm:w-full">
            <div class="flex justify-between items-center px-4 md:px-10 py-8 md:py-10">
              <h3><slot name="title"/></h3>
              <div class="ml-2 flex items-center">
                <slot name="interactive"/>
              </div>
            </div>
            <div class="px-4 md:px-10 py-5">
                <slot name="body"/>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <slot name="footer"/>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Modal2'
}
</script>
