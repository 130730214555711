<template>
    <div :class="settings">
        <label for="location" class="block text-sm font-bold text-gray-700" :class="settingslabel"><slot name="title"/></label>
        <select :value="value" v-on:input="$emit('input', $event.target.value)" id="location" name="location" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md">
            <slot name="options"/>
        </select>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['settings', 'settingslabel', 'value']
}
</script>
