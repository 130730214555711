<template>
    <div class="flex items-center">
        <div class="flex items-center border border-gray-200 rounded-md h-full">
            <button class="font-bold rounded-md focus:outline-none text-gray-500 text-2xl px-2 py-4 pr-0 md:pr-4 md:pl-4" v-on:click="subtract(1)">-</button>
            <input name="quantity" id="complete-time" :value="quantity" class="focus:ring-0 focus:text-primary text-gray-600 font-bold block py-5 border-transparent rounded-md outline-none w-8 text-center">
            <button class="font-bold rounded-md focus:outline-none text-gray-500 text-2xl px-2 py-4 pl-0 md:pr-4 md:pl-4" v-on:click="add(1)">+</button>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            quantity: this.value || 1
        }
    },
    props: {
        value: {
            type: Number,
            default: 1
        }
    },
    watch: {
        quantity () {
            this.$emit('input', this.quantity)
        }
    },
    methods: {
        add: function (inc) {
            this.quantity += inc
        },
        subtract: function (dec) {
            if (this.quantity <= 1) {} else {
                this.quantity -= dec
            }
        }
    }
}
</script>

<style>

</style>
