<template>
    <a :class="state" class="food-nav-link text-white font-bold py-1" v-bind:href="'#'+ uuid"><slot/></a>
</template>

<script>
export default {
    props: {
        state: {
            type: String
        },
        uuid: {
            type: String
        }
    }
}
</script>

<style>
    .food-nav-link {
        position: relative;
    }
    .food-nav-link::before {
        transition: all 0.25s ease;
        position: absolute;
        content: '';
        width: 100%;
        bottom: 0;
        height: 3px;
        left: 0;
        background: transparent;
    }
    .food-nav-link.active::before {
        background: white;
    }
</style>
