<template>
  <div class="md:bg-white rounded-lg md:p-10">
    <slot/>
  </div>
</template>

<script>
export default {
    name: 'CardBox'
}
</script>
