/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
    <div class="md:bg-gray-100 pb-28 lg:pb-0">
        <transition name="fade2">
        <div v-show="!restaurant.uuid || restaurant.uuid == '__loading__'" style="position: fixed; display: flex; top: 0; left: 0; width: 100vw; height: 100vh; background: #fff; z-index: 100">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; max-width: 50vw; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#505050" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="0.9615384615384615s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
        </path>
        <!-- [ldio] generated by https://loading.io/ --></svg>
        </div>
        </transition>
        <restaurant-header @location="orderLocation" :location1="location"/>
        <hero />
        <a ref="menuscroll"></a>
        <div class="bg-primary sticky top-0 z-50">
            <orders-not-possible v-show="!$store.state.realtimedata.orderPossible" class="z-100"/>
            <shop-closed v-show="!preOrder && !$store.state.realtimedata.open" @preOrderWhenClosed="preOrderMode" :location="$store.state.order.location" class="z-100"/>
            <div class="max-w-8xl mx-auto px-4 lg:px-8 flex items-center py-8">
                <div class="mr-5 md:mr-14 flex items-center">
                    <button @click="openSearch" class="focus:outline-none"><icon-search class="h-8 text-white"/></button>
                    <transition name="fade">
                        <div v-show="isSearchVisable" class="food-search ml-4 transition inline-block" >
                            <input ref="search" v-model="searchVal" :placeholder="$t('restaurant.product_search')" class="text-white placeholder-white font-bold border-white outline-none pb-1">
                        </div>
                    </transition>
                </div>
                <button v-if="moreScroll > 0" @click="slideBarLeft" class="inline-flex items-center font-bold rounded-md text-gray-600 focus:outline-none text-xl ml-auto pr-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                </button>
                <div class="overflow-hidden relative">
                    <div class="bg-gradient-to-l from-primary absolute w-40 right-0 h-full"></div>
                    <div id="navbar" class="flex space-x-6 md:space-x-14 whitespace-nowrap overflow-y-scroll no-scrollbar">
                        <food-nav-link :state="currentCategory === category.uuid || (currentCategory === '' && idx === 0)? 'active' : ''" v-for="(category, idx) in filteredCategories.filter(c => !unavailableCategory.includes(c.uuid))" :key="category.uuid" :uuid="category.uuid">{{ category.title }}</food-nav-link>
                    </div>
                </div>
                <button @click="slideBar" class="inline-flex items-center font-bold rounded-md text-gray-600 focus:outline-none text-xl ml-auto pl-5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="max-w-8xl mx-auto px-4 lg:px-8 flex flex-wrap mb-20">
            <div class="w-full lg:w-4/7 ">
                <div class="mt-10">
                    <div v-if="restaurant.topMessage && !searchVal" class="web-mobile whitespace-pre-wrap flex overflow-hidden relative rounded-lg pl-8 pr-8 pb-8 pt-8 mb-10 bg-white">
                        <strong class="text-xl text-black">{{parsedTopMessage}}</strong>
                    </div>
                    <div v-if="restaurant.topMessage && !searchVal" class="mobile-small whitespace-pre-wrap flex overflow-hidden relative break-words rounded-lg pl-8 pr-8 pb-8 pt-8 mb-10 bg-white border border-gray-300 ">
                        <p class="text-xl font-semibold text-black" >{{parsedTopMessage}}</p>
                    </div>
                    <div v-if="!searchVal" class="grid grid-flow-row grid-cols-1 gap-2 md:gap-7">
                        <div class="mobile-small">
                            <h4 class="mb-3">{{ 'Alle Produkte' }}</h4>
                        </div>
                        <category-section @jumpto="jumpto($event)" v-for="category in filteredCategories" :key="category.uuid" @notAvailable="addUnavailable" @available="removeUnavailable" :category="category" :stateVegetarian="stateVegetarian" :stateVegan="stateVegan" :stateGlutenFree="stateGlutenFree" :stateBasic="stateBasic" :location="$store.state.order.location" :timed="$store.state.order.timeType.timed" :deliveryTime="$store.state.order.deliveryTime" ref="categories" />
                    </div>
                    <div v-if="restaurant.bottomMessage && !searchVal" class="web-mobile whitespace-pre-wrap flex relative overflow-hidden rounded-lg pl-8 pr-8 pb-8 pt-8 mt-5 bg-white">
                        <strong class="text-xl text-black">{{parsedBottomMessage}}</strong>
                    </div>
                    <div v-if="restaurant.bottomMessage && !searchVal" class="mobile-small whitespace-pre-wrap flex relative overflow-hidden rounded-lg pl-8 pr-8 pb-8 pt-8 mt-5 bg-white border border-gray-300">
                        <strong class="text-xl text-black">{{parsedBottomMessage}}</strong>
                    </div>
                    <div v-if="searchVal" class="grid grid-flow-row grid-cols-1 gap-2 relative">
                        <strong v-if="searchResultProduct.length > 1" class="text-black mb-3">{{searchResultProduct.length}} {{'Ergebnisse'}}</strong>
                        <strong v-if="searchResultProduct.length === 1" class="text-black mb-3">{{searchResultProduct.length}} {{'Ergebnis'}}</strong>
                        <transition-group name="list" tag="ul">
                            <li v-for="product in searchResultProduct" :key="product.uuid">
                                <product-box @jumpto="jumpto($event)" :product="product" :category="$store.state.catalog.categories.find(c => c.products.includes(product.uuid))" :stateVegan="stateVegan" :stateVegetarian="stateVegetarian" :stateBasic="stateBasic" :stateGlutenFree="stateGlutenFree" :timed="$store.state.order.timeType.timed" :deliveryTime="$store.state.order.deliveryTime"/>
                            </li>
                        </transition-group>
                        <strong class="text-black" v-if="searchResultProduct.length === 0">Keine Ergebnisse gefunden.</strong>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-3/7 lg:pl-10 order-first lg:order-last">
                <div class="mt-10">
                    <div class="grid grid-cols-3 overflow-hidden divide-gray-200 divide-x bg-white rounded-lg">
                        <div class="flex items-center justify-center py-4 cursor-pointer" @click="(((stateVegan = false) && (stateVegetarian = false)) || ((stateVegetarian = false) && (stateVegan = false)))">
                            <span :class="{'text-primary': !stateVegetarian && !stateVegan, 'text-gray-500': stateVegetarian || stateVegan}" class="text-sm md:text-base font-bold hover:opacity-80 text-primary">{{$t('restaurant.all')}}</span>
                        </div>
                        <div class="flex items-center justify-center py-4 cursor-pointer" @click="(stateVegetarian = true) && (stateVegan = false)">
                            <span :class="{'text-primary': stateVegetarian, 'text-gray-500': !stateVegetarian}" class="text-sm md:text-base font-bold hover:opacity-80">{{$t('restaurant.vegetarian')}}</span>
                        </div>
                        <div class="flex items-center justify-center py-4 cursor-pointer" @click="(stateVegan = true) && (stateVegetarian = false)">
                            <span :class="{'text-primary': stateVegan, 'text-gray-500': !stateVegan}" class="text-sm md:text-base font-bold hover:opacity-80">{{$t('restaurant.vegan')}}</span>
                        </div>
                    </div>
                    <div class="mt-1 grid grid-cols-2 overflow-hidden divide-gray-200 divide-x bg-white rounded-lg">
                        <div class="flex items-center justify-center py-4 cursor-pointer" @click="glutenToggle">
                            <span :class="{'text-primary': stateGlutenFree, 'text-gray-500': !stateGlutenFree}" class="text-sm md:text-base font-bold hover:opacity-80">{{$t('restaurant.glutenfree')}}</span>
                        </div>
                        <div class="flex items-center justify-center py-4 cursor-pointer" @click="basicToggle">
                            <span :class="{'text-primary': stateBasic, 'text-gray-500': !stateBasic}" class="text-sm md:text-base font-bold hover:opacity-80">{{$t('restaurant.basic')}}</span>
                        </div>
                    </div>
                </div>
                <div class="mt-10 bg-white p-0 md:p-10 rounded-lg">
                    <h4 class=" mb-5">{{ 'Liefertermin' }}</h4>
                    <div class="mt-5 grid grid-cols-2 gap-5">
                        <checkbox v-if="!preOrder" v-model="now" checkboxname="Sofort">
                            <span class=" font-normal text-base text-gray-500">{{ $t('restaurant.now') }}</span>
                        </checkbox>
                        <checkbox v-show="!preOrder" v-model="timed" checkboxname="Termin" >
                            <span class=" font-normal text-base text-gray-500">{{ $t('restaurant.select_date') }}</span>
                        </checkbox>
                    </div>
                    <div v-if="!this.$store.state.realtimedata.open && preOrder"><strong>Unsere Öffnungszeiten</strong>
                            <div v-for="(openingHour, index) in (restaurant.locations.find(l => l.uuid === $store.state.order.location) || {}).openingHours" :key="index" class="w-full mt-2">
                                <div class="flex items-center w-8 mt-1 mb-1">
                                    <div class="flex mr-5">
                                    <button class="btn items-center font-bold rounded-md focus:outline-none text-gray-500 bg-primary sm transparent justify-center pointer-events-none">
                                            <span v-if="openingHour.day === '1'">{{ $t(`dashboard.short_monday`) }}</span>
                                            <span v-if="openingHour.day === '2'">{{ $t(`dashboard.short_tuesday`) }}</span>
                                            <span v-if="openingHour.day === '3'">{{ $t(`dashboard.short_wednesday`) }}</span>
                                            <span v-if="openingHour.day === '4'">{{ $t(`dashboard.short_thursday`) }}</span>
                                            <span v-if="openingHour.day === '5'">{{ $t(`dashboard.short_friday`) }}</span>
                                            <span v-if="openingHour.day === '6'">{{ $t(`dashboard.short_saturday`) }}</span>
                                            <span v-if="openingHour.day === '0'">{{ $t(`dashboard.short_sunday`) }}</span>
                                    </button>
                                    </div>
                                    <div>
                                        {{$t('restaurant.from_time')}}<span class="ml-5 mr-16">{{openingHour.from[0]}}:{{openingHour.from[1]}}</span>{{$t('restaurant.to_time')}}<span class="ml-5 mr-5">{{openingHour.to[0]}}:{{openingHour.to[1]}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="mt-5 mb-3">
                        <transition name="slide-fade">
                        <datepicker-block v-if="$store.state.order.timeType.timed" v-model="deliveryTime">
                        </datepicker-block>
                        </transition>
                    </div>
                    <strong v-if="!this.$store.state.realtimedata.open &&preOrder && !legitOrderTime" class="text-red ">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        Bitte wählen Sie ein Datum im Rahmen der oben aufgelisteten Öffnungszeiten aus!
                    </strong>
                    <div v-if="activeLocations.length > 1" class="mobile">
                        <h4 class="mt-10 mb-5">{{ $t('restaurant.location') }}</h4>
                        <multiselect class="p-5"
                            :options="activeLocations"
                            v-model="location"
                            :track-by="'uuid'"
                            label="title"
                            :deselectLabel="$t('dashboard.remove')"
                            :selectedLabel="$t('dashboard.selected')"
                            :selectLabel="$t('dashboard.select')"
                            :multiple="false"
                            :placeholder="'Ort auswählen'"
                            >
                            <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                            <template slot="option" slot-scope="{ option }">{{ option.generalTitle }}</template>
                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.generalTitle }}</strong></template>
                        </multiselect>
                    </div>
                    <h4 class="mt-10 mb-5">{{ $t('restaurant.selection') }}</h4>
                    <div class="grid grid-cols-2 sm:grid-flow-col gap-5" :class="{'grid1': restaurant.deliveryActive}">
                        <div v-if="!restaurant.deliveryActive">
                            <button @click="$store.state.order.method.method = 'delivery'" :class="{'border-primary text-primary': $store.state.order.method.method=='delivery', 'border-gray-500 text-gray-500': $store.state.order.method.method=='pick-up'}" class="border rounded p-6 font-bold relative focus:outline-none hover:opacity-80 w-full h-full">
                                {{ $t('restaurant.delivery') }}
                                <span v-if="delivery_price!==0 && delivery_price > 0"> (+{{$n(delivery_price /100, 'currency')}})</span>
                                <span v-if="delivery_price!==0 && delivery_price < 0"> ({{$n(delivery_price /100, 'currency')}})</span>
                                <span v-if="$store.state.order.method.method == 'delivery'" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full">
                                    <icon-check class="h-8 text-white"/>
                                </span>
                            </button>
                            <!--<div v-if="delivery_maxValue" :class="{'text-black': $store.state.order.method.method=='delivery'}">
                                <strong class="text-xs">{{'Der Aufpreis von'}}<strong :class="{'text-primary': $store.state.order.method.method=='delivery'}"> {{$n(delivery_price /100, 'currency')}} </strong>{{'gilt nur bei einem Bestellwert unter'}} <strong :class="{'text-primary': $store.state.order.method.method=='delivery'}">{{ $n(delivery_maxValue /100, 'currency') }}</strong></strong>
                            </div>-->
                        </div>
                        <div>
                            <button @click="$store.state.order.method.method = 'pick-up'" :class="{'border-primary text-primary': $store.state.order.method.method=='pick-up', 'border-gray-500 text-gray-500': $store.state.order.method.method=='delivery'}" class="border rounded p-6 font-bold relative focus:outline-none hover:opacity-80 w-full h-full">
                                {{ $t('restaurant.pickup') }}
                                <span v-if="pickup_price!==0 && pickup_price > 0"> (+{{$n(pickup_price /100, 'currency')}})</span>
                                <span v-if="pickup_price!==0 && pickup_price < 0"> ({{$n(pickup_price /100, 'currency')}})</span>
                                <span v-if="$store.state.order.method.method == 'pick-up'" class="absolute -top-1.5 -right-1.5 bg-primary rounded-full">
                                    <icon-check class="h-8 text-white"/>
                                </span>
                            </button>
                            <!--<div v-if="pickup_maxValue" :class="{'text-black': $store.state.order.method.method=='pick-up'}">
                                <strong class="text-xs">{{'Der Aufpreis von'}}<strong :class="{'text-primary': $store.state.order.method.method=='pick-up'}"> {{$n(pickup_price /100, 'currency')}}</strong> {{'gilt nur bei einem Bestellwert unter'}} <strong :class="{'text-primary': $store.state.order.method.method=='pick-up'}">{{ $n(pickup_maxValue /100, 'currency') }}</strong></strong>
                            </div>-->
                        </div>
                    </div>
                </div>
                <!-- cart - only desktop -->
                <div class="my-10 bg-white p-10 rounded-t-lg sticky top-32 hidden lg:block" >
                    <div class="flex items-center justify-between">
                        <div class="flex items-center">
                            <div class="cart-icon">
                                <div class="relative mr-4">
                                    <div class="flex items-center justify-center absolute w-full h-full">
                                        <div class="font-bold text-white text-base mt-3">{{ $store.state.order.lineItems.reduce((acc,li)=>acc+li.quantity, 0) }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-8 fill-current text-primary" viewBox="0 0 24.167 29">
                                        <path id="Pfad_838" data-name="Pfad 838" d="M23.75,8.458H18.917V4.833a4.833,4.833,0,0,0-9.667,0V8.458H4.417L2,29H26.167ZM10.458,4.833a3.625,3.625,0,0,1,7.25,0V8.458h-7.25Z" transform="translate(-2)" />
                                    </svg>
                                </div>
                            </div>
                            <h2>{{ $t('restaurant.cart') }}</h2>
                        </div>
                        <button-l @click.native="$store.state.order.lineItems = []"  href="javascript:" style="justify-content: flex-end;" settings="line justify-center">{{ $t('restaurant.empty') }}</button-l>
                    </div>
                    <cart-empty v-if="$store.getters.renderedOrder.lineItems.length == 0" />
                    <div class="mt-10 overflow-auto" style="max-height: calc(100vh - 430px)">
                        <SmoothReflow>
                            <transition-group name="list" tag="p">
                                <sidebar-cart-item v-for="lineItem in $store.getters.renderedOrder.lineItems" :key="lineItem.uuid" :line-item="lineItem" :minOrderSet="minOrderSet" @remove="$store.state.order.removeLineItem(lineItem.uuid)" />
                            </transition-group>
                        </SmoothReflow>
                    </div>
                    <div class="flex justify-center flex-col">
                        <span v-if="catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached" class="text-sm text-center mt-4" :class="{'mb-3': (pickup_price === 0 && $store.state.order.method.method === 'pick-up') || (delivery_price === 0 && $store.state.order.method.method === 'delivery')}">{{ $t('restaurant.minOrderValue') }} <strong :class="{'text-red': !valueReached}">{{$n(catalog.getMethodByUuid($store.state.order.method.method).minOrderValue /100, 'currency')}}</strong></span>
                        <div v-if="delivery_maxValue && delivery_price != 0 && $store.state.order.method.method=='delivery'" class="text-sm text-center mt-1 mb-4">
                            <span v-if="!maxValueReached" >{{'Aktueller Bestellwert: '}} <strong class="text-black">{{$n(priceAllLineItems / 100, 'currency')}}</strong> <span v-if="valueReached">{{'+ Lieferkosten'}} <strong>{{$n(delivery_price / 100, 'currency')}}</strong></span></span>
                            <br/>
                            <span class="" v-if="!maxValueReached && valueReached" >{{'Keine Lieferkosten ab: '}} <strong class="text-green">{{ $n(delivery_maxValue /100, 'currency')}}</strong></span>
                            <div v-if="maxValueReached" class="text-green flex justify-center">Keine Lieferkosten
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                        <div v-if="pickup_maxValue && pickup_price != 0 && $store.state.order.method.method=='pick-up'" class="text-sm text-center mt-1 mb-4">
                            <span v-if="!maxValueReached" >{{'Aktueller Bestellwert: '}} <strong class="text-black">{{$n(priceAllLineItems / 100, 'currency')}}</strong> <span v-if="valueReached">{{'+ Abholkosten'}} <strong>{{$n(pickup_price / 100, 'currency')}}</strong></span></span>
                            <br/>
                            <span class="" v-if="!maxValueReached && valueReached" >{{'Keine Abholkosten ab: '}} <strong class="text-green">{{ $n(pickup_maxValue /100, 'currency')}}</strong></span>
                            <div v-if="maxValueReached" class="text-green flex justify-center">Keine Abholkosten
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                        <button-b @click.native="$router.push({ name: 'order-checkout', params: {maxValueReached: maxValueReached, location: location} })" :disabled="((catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached)) || lineItemLength < 1 || !legitOrderTime" :class="{'bg-gray-500 cursor-default': (catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached) || lineItemLength < 1 || !legitOrderTime}" class="bg-primary w-full justify-center lg">{{$t('restaurant.order')}} <span v-if="valueReached && catalog.getMethodByUuid($store.state.order.method.method).minOrderValue">({{ $n($store.getters.renderedOrder.orderPrice/100, 'currency') }})</span></button-b>
                        <!-- :disabled="!valueReached" :class="{'bg-gray-500': !valueReached}"    cursor-default    -->
                        <strong v-if="preOrder && !legitOrderTime" class="text-red flex justify-center mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6 mr-1" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            Bitte überprüfen Sie das Bestelldatum!
                        </strong>
                        <span v-if="activeLocations.length > 1" class="text-base font-semibold text-black flex justify-center mt-2"> Standort: {{(activeLocations.find(l => l.uuid === $store.state.order.location) || {}).generalTitle}}</span>
                        <span class="text-xs font-semibold text-gray-400 flex justify-center mt-2" v-if="!compared && minOrderSet"><span class="text-red">* </span> Nicht im Mindestbestellwert inbegriffen.</span>
                    </div>
                    <div class="absolute left-0 overflow-hidden w-full" style="bottom: -17px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="550.037" height="17.199" viewBox="0 0 550.037 17.199">
                            <path id="Vereinigungsmenge_8" data-name="Vereinigungsmenge 8" d="M-996.878-362.263h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.957,17.2Zm-23.916,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.957,17.2Zm-23.916,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.956,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.957,17.2Zm-23.915,0h23.914l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Zm-23.914,0h23.914l-11.957,17.2Zm-23.915,0h23.914l-11.957,17.2Zm-23.915,0h23.915l-11.957,17.2Z" transform="translate(1523 362.263)" fill="#fff"/>
                        </svg>
                    </div>
                </div><!-- END: cart - only desktop -->
                <div class="shadow-xl border-t rounded-t-lg fixed bottom-0 left-0 px-4 pt-4 pb-2 bg-white w-full lg:hidden" :class="{'z-0': !$store.state.realtimedata.orderPossible, 'z-0': !$store.state.realtimedata.open, 'z-50': $store.state.realtimedata.orderPossible && $store.state.realtimedata.open}">
                    <div class="flex justify-center flex-col">
                        <button-b @click.native="showModalCart" :disabled="$store.state.order.lineItems.length === 0" :class="{'bg-gray-500': $store.state.order.lineItems.length === 0}" class="z-50 bg-primary w-full justify-center lg">{{$t('restaurant.my_cart')}} <span v-if="priceAllLineItems !== 0"> ({{$n(priceAllLineItems / 100, 'currency')}})</span></button-b>
                        <span v-if="catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached" class="text-xs text-black text-center mt-2" >{{ $t('restaurant.minOrderValue') }} <strong :class="{'text-red': !valueReached}">{{$n(catalog.getMethodByUuid($store.state.order.method.method).minOrderValue /100, 'currency')}}</strong></span>
                        <span v-else-if="!maxValueReached && ($store.state.order.method.method === 'delivery') && delivery_price != 0" class="text-xs text-black text-center font-semibold" :class="{'mt-2': valueReached}">{{ 'Lieferkosten:' }} <strong :class="{'text-black': !maxValueReached}">{{$n(catalog.getMethodByUuid($store.state.order.method.method).priceAmount /100, 'currency')}}</strong></span>
                        <span v-else-if="!maxValueReached && ($store.state.order.method.method === 'delivery') && valueReached" class="text-xs text-black text-center font-semibold">{{'Keine Lieferkosten ab:'}} <span class="text-green">{{$n(catalog.getMethodByUuid($store.state.order.method.method).maxValue /100, 'currency')}}</span></span>
                        <span v-else-if="!maxValueReached && ($store.state.order.method.method === 'pick-up') && pickup_price != 0" class="text-xs text-black text-center font-semibold" :class="{'mt-2': valueReached}">{{ 'Abholkosten:' }} <strong :class="{'text-black': !maxValueReached}">{{$n(catalog.getMethodByUuid($store.state.order.method.method).priceAmount /100, 'currency')}}</strong></span>
                        <span v-else-if="!maxValueReached && ($store.state.order.method.method === 'pick-up') && valueReached" class="text-xs text-black text-center font-semibold">{{'Keine Abholkosten ab:'}} <span class="text-green">{{$n(catalog.getMethodByUuid($store.state.order.method.method).maxValue /100, 'currency')}}</span></span>
                        <div v-else-if="maxValueReached && ($store.state.order.method.method === 'delivery') && delivery_price !== 0" class="text-green font-semibold text-xs flex justify-center mt-2">Keine Lieferkosten
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div v-else-if="maxValueReached && ($store.state.order.method.method === 'pick-up') && pickup_price !== 0" class="text-green font-semibold text-xs flex justify-center mt-2">Keine Abholkosten
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <modal-mobile-cart v-if="isModalCartVisible" @close="closeModalCart">
                    <template #head>
                        <div class="flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="cart-icon">
                                    <div class="relative mr-4">
                                        <div class="flex items-center justify-center absolute w-full h-full">
                                            <div class="font-bold text-white text-base mt-3">{{ $store.state.order.lineItems.reduce((acc,li)=>acc+li.quantity, 0) }}</div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8" viewBox="0 0 24.167 29">
                                            <path id="Pfad_838" data-name="Pfad 838" d="M23.75,8.458H18.917V4.833a4.833,4.833,0,0,0-9.667,0V8.458H4.417L2,29H26.167ZM10.458,4.833a3.625,3.625,0,0,1,7.25,0V8.458h-7.25Z" transform="translate(-2)" fill="#e3523b"/>
                                        </svg>
                                    </div>
                                </div>
                                <h2>{{ $t('restaurant.cart') }}</h2>
                            </div>
                            <button-l @click.native="$store.state.order.lineItems = []"  href="javascript:" settings="line">{{ $t('restaurant.empty') }}</button-l>
                        </div>
                    </template>
                    <template #items>
                        <cart-empty v-if="$store.getters.renderedOrder.lineItems.length == 0" />
                        <SmoothReflow>
                            <transition-group name="list" tag="p">
                                <sidebar-cart-item v-for="lineItem in $store.getters.renderedOrder.lineItems" :key="lineItem.uuid" :line-item="lineItem" :minOrderSet="minOrderSet" @remove="$store.state.order.removeLineItem(lineItem.uuid)" />
                            </transition-group>
                        </SmoothReflow>
                    </template>
                    <template #checkout>
                        <button-b @click.native="$router.push({ name: 'order-checkout', params: {maxValueReached: maxValueReached, location: location} })" :disabled="((catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached)) || lineItemLength < 1 || !legitOrderTime" :class="{'bg-gray-500 cursor-default': (catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached) || lineItemLength < 1 || !legitOrderTime}" class="bg-primary w-full justify-center lg">{{$t('restaurant.order')}} <span v-if="valueReached">({{ $n($store.getters.renderedOrder.orderPrice/100, 'currency') }})</span></button-b>
                        <span v-if="catalog.getMethodByUuid($store.state.order.method.method).minOrderValue && !valueReached" class="text-xs text-center mt-2">{{ $t('restaurant.minOrderValue') }} <strong :class="{'text-red': !valueReached}">{{$n(catalog.getMethodByUuid($store.state.order.method.method).minOrderValue /100, 'currency')}}</strong></span>
                        <div v-if="delivery_maxValue && delivery_price != 0 && $store.state.order.method.method=='delivery'" class="text-xs text-center" :class="{'mt-2': valueReached}">
                            <span v-if="!maxValueReached" >{{'Aktueller Bestellwert: '}} <strong class="text-black">{{$n(priceAllLineItems / 100, 'currency')}}</strong> <span v-if="valueReached">{{'+ Lieferkosten'}} <strong>{{$n(delivery_price / 100, 'currency')}}</strong></span></span>
                            <p class="text-xs" v-if="!maxValueReached && valueReached" >{{'Keine Lieferkosten ab: '}} <strong class="text-green">{{ $n(delivery_maxValue /100, 'currency')}}</strong></p>
                            <div v-if="maxValueReached" class="text-green font-semibold text-xs flex justify-center">Keine Lieferkosten
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                        <div v-if="pickup_maxValue && pickup_price != 0 && $store.state.order.method.method=='pick-up'" class="text-xs text-center mt-1" :class="{'mt-2': valueReached}">
                            <span v-if="!maxValueReached" >{{'Aktueller Bestellwert: '}} <strong class="text-black">{{$n(priceAllLineItems / 100, 'currency')}}</strong> <span v-if="valueReached">{{'+ Abholkosten'}} <strong>{{$n(pickup_price / 100, 'currency')}}</strong></span></span>
                            <span class="" v-if="!maxValueReached && valueReached" >{{'Keine Abholkosten ab: '}} <strong class="text-green">{{ $n(pickup_maxValue /100, 'currency')}}</strong></span>
                            <div v-if="maxValueReached" class="text-green flex font-semibold justify-center">Keine Abholkosten
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1 align-top" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                        <strong v-if="!$store.state.realtimedata.open && preOrder && !legitOrderTime" class="text-red text-xs flex justify-center mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" h-5 w-5 mr-1" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            Bitte überprüfen Sie das Bestelldatum!
                        </strong>
                        <span class="text-xs font-semibold text-gray-400 flex justify-center mt-2" v-if="!compared && minOrderSet"><span class="text-red">* </span> Nicht im Mindestbestellwert inbegriffen.</span>
                        <span v-if="activeLocations.length > 1" class="text-xs font-semibold text-black flex justify-center mt-1"> Standort: {{activeLocations.find(l => l.uuid === $store.state.order.location).generalTitle}}</span>
                    </template>
                </modal-mobile-cart>
            </div>
        </div>
        <app-section position="footer" />
        <restaurant-footer/>
    </div>
</template>

<script>
import RestaurantHeader from '@/components/layout/RestaurantHeader.vue'
import Hero from '@/components/restaurant/Hero.vue'
import CategorySection from '@/components/restaurant/CategorySection.vue'
import ButtonL from '@/components/ui/ButtonL.vue'
import SidebarCartItem from '@/components/restaurant/SidebarCartItem.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import FoodNavLink from '@/components/restaurant/FoodNavLink.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import ModalMobileCart from '@/components/restaurant/ModalMobileCart.vue'
import ProductBox from '@/components/restaurant/ProductBox.vue'
import CartEmpty from '@/components/restaurant/CartEmpty.vue'
import RestaurantFooter from '@/components/layout/RestaurantFooter.vue'
import { RenderedAvailability } from 'delivery-boosting-2021-model'
import OrdersNotPossible from '@/components/modals/OrdersNotPossible.vue'
import ShopClosed from '@/components/modals/ShopClosed.vue'
import Multiselect from 'vue-multiselect'
import DatepickerBlock from '@/components/ui/DatepickerBlock.vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import { liquid } from '@/liquid'

// import IconX from '@/components/icons/IconX.vue'

export default {
    data () {
        return {
            isModalCartVisible: false,
            isSearchVisable: false,
            searchVal: '',
            currentCategory: '',
            stateVegetarian: false,
            stateVegan: false,
            stateGlutenFree: false,
            stateBasic: false,
            location: {},
            unavailableCategory: [],
            x: false,
            moreScroll: 0,
            lessScroll: 0,
            preOrder: false
        }
    },
    components: {
        RestaurantHeader,
        Hero,
        ButtonL,
        /* IconX, */
        CategorySection,
        SidebarCartItem,
        ButtonB,
        IconSearch,
        FoodNavLink,
        IconCheck,
        ModalMobileCart,
        ProductBox,
        RestaurantFooter,
        CartEmpty,
        OrdersNotPossible,
        ShopClosed,
        Multiselect,
        DatepickerBlock,
        Checkbox
    },
    computed: {
        minOrderSet () {
            return this.catalog.getMethodByUuid(this.$store.state.order.method.method).minOrderValue !== 0
        },
        legitOrderTime () {
            if (this.preOrder) {
                const date = new Date(this.deliveryTime)
                const day = date.getDay()
                const minute = date.getMinutes()
                const hour = date.getHours()
                const time = hour + (minute / 100)
                const compared = []
                const openingHours = this.restaurant.locations.find(l => l.uuid === this.$store.state.order.location).openingHours.filter(o => parseInt(o.day) === day)
                for (const o of openingHours) {
                    const oFrom = parseInt(o.from[0]) + (parseInt(o.from[1]) / 100)
                    const oTo = parseInt(o.to[0]) + (parseInt(o.to[1]) / 100)
                    compared.push((oFrom <= time) && (time <= oTo))
                }
                return compared.includes(true)
            } else {
                return true
            }
        },
        activeLocations () {
            return this.$store.state.restaurant.locations.filter(l => l.active === true)
        },
        parsedTopMessage () {
            return liquid.parseAndRenderSync(this.restaurant.topMessage, {
                order: this.$store.getters.renderedOrder,
                restaurant: this.$store.state.restaurant
            })
        },
        parsedBottomMessage () {
            return liquid.parseAndRenderSync(this.restaurant.bottomMessage, {
                order: this.$store.getters.renderedOrder,
                restaurant: this.$store.state.restaurant
            })
        },
        valueReached () {
            var cartValue = 0
            for (const l of this.$store.getters.renderedOrder.lineItems) {
                cartValue += l.minOrderValuePrice
            }
            const minOrderValue = this.catalog.getMethodByUuid(this.$store.state.order.method.method).minOrderValue
            return (cartValue >= minOrderValue)
        },
        maxValueReached () {
            var sum = 0
            const method = this.$store.state.order.method.method
            for (const l of this.$store.getters.renderedOrder.lineItems) {
                sum += l.minOrderValuePrice
            }
            if (method === 'delivery') {
                if (sum < this.delivery_maxValue) {
                    return false
                }
            } else if (method === 'pick-up') {
                if (sum < this.pickup_maxValue) {
                    return false
                }
            }
            return true
        },
        priceAllLineItems () {
            var sum = 0
            for (const l of this.$store.getters.renderedOrder.lineItems) {
                sum += l.minOrderValuePrice
            }
            return sum
        },
        priceToCompare () {
            var sum = 0
            for (const l of this.$store.getters.renderedOrder.lineItems) {
                sum += l.price
            }
            return sum
        },
        compared () {
            return this.priceAllLineItems === this.priceToCompare
        },
        delivery_price () {
            return (this.catalog.getMethodByUuid('delivery') || {}).priceAmount
        },
        pickup_price () {
            return (this.catalog.getMethodByUuid('pick-up') || {}).priceAmount
        },
        delivery_maxValue () {
            return (this.catalog.getMethodByUuid('delivery') || {}).maxValue
        },
        pickup_maxValue () {
            return (this.catalog.getMethodByUuid('pick-up') || {}).maxValue
        },
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        outputSearch () {
            const tempArr = this.catalog.products
            const search = this.searchResultProduct.map(u => u.uuid)
            return [tempArr.sort(
                (a, b) => (search.includes(b.uuid) ? 1 : 0) - (search.includes(a.uuid) ? 1 : 0)
            ), search.length]
        },
        filteredCategories () {
            // FIXME: was macht business logic hier im frontend??
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            // eslint-disable-next-line no-unreachable
            if (this.timed && this.deliveryTime) {
                return this.catalog.categories.filter(c => c.availability.locations.includes(this.$store.state.order.location))
            } else {
                return this.catalog.categories.filter(c => c.availability.locations.includes(this.$store.state.order.location))
            }
        },
        searchResultProduct () {
            const categories = this.filteredCategories
            const filteredProductUuids = Array.from(new Set(categories.map(c => c.products).flat()))
            const filteredProducts = this.catalog.getProducts(filteredProductUuids)
            const prods = filteredProducts.filter(
                p => (p.title && p.title.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                    .indexOf(this.searchVal.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0) ||
                    (p.description && p.description.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                        .indexOf(this.searchVal.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0) ||
                    (p.no && p.no.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                        .indexOf(this.searchVal.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) >= 0)
            )
            if (this.timed && this.$store.state.order.deliveryTime) {
                prods.filter(p => (RenderedAvailability.checkAvailability(p.availability, new Date(this.$store.state.order.deliveryTime)).state) && (!this.stateVegetarian || p.properties.vegetarian || p.properties.vegan) && (!this.stateVegan || p.properties.vegan) && (!this.stateGlutenFree || p.properties.glutenFree) && (!this.stateBasic || p.properties.basic))
                if (this.activeLocations.length === 1) {
                    return prods
                } else {
                    return prods.filter(p => p.availability.locations.includes(this.$store.state.order.location))
                }
            } else {
                prods.filter(p => (RenderedAvailability.checkAvailability(p.availability, new Date()).state) && (!this.stateVegetarian || p.properties.vegetarian || p.properties.vegan) && (!this.stateVegan || p.properties.vegan) && (!this.stateGlutenFree || p.properties.glutenFree) && (!this.stateBasic || p.properties.basic))
                if (this.activeLocations.length === 1) {
                    return prods
                } else {
                    return prods.filter(p => p.availability.locations.includes(this.$store.state.order.location))
                }
            }
        /*
            var prods = []
            var len = this.catalog.products.length
            for (var i = 0; i < len; i++) {
                prods.push(this.catalog.products[i].title)
            }
            return prods */
        },
        now: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.x
                return this.$store.state.order.timeType.now
            },
            set (val) {
                this.x = !this.x
                this.$store.state.order.timeType.now = val
                this.x = !this.x
                if (this.now === this.timed) {
                    this.$store.state.order.timeType.timed = !this.now
                    this.x = !this.x
                }
                this.removeUnavailableFromCart()
            }
        },
        timed: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.x
                return this.$store.state.order.timeType.timed
            },
            set (val) {
                this.x = !this.x
                this.$store.state.order.timeType.timed = val
                this.x = !this.x
                if (this.now === this.timed) {
                    this.$store.state.order.timeType.now = !this.timed
                    this.x = !this.x
                }
                this.removeUnavailableFromCart()
            }
        },
        deliveryTime: {
            get () {
                // eslint-disable-next-line no-unused-expressions
                this.x
                return this.$store.state.order.deliveryTime.toISOString()
            },
            set (val) {
                this.$set(this.$store.state.order, 'deliveryTime', new Date(val))
                this.x = !this.x
                // this.$store.state.order.deliveryTime = new Date(val)
            }
        },
        lineItemLength () {
            return this.$store.state.order.lineItems.length
        }
    },
    methods: {
        jumpto (evt) {
            console.log(evt)
            this.now = false
            this.timed = true
            this.deliveryTime = evt
        },
        removeUnavailableFromCart () {
            const uuids = this.$store.state.order.lineItems.map(l => l.product)
            for (const uuid of uuids) {
                const product = this.$store.state.catalog.products.find(p => p.uuid === uuid)
                const available = RenderedAvailability.checkAvailability(product.availability, this.now ? new Date() : new Date(this.deliveryTime)).available
                if (!available) {
                    console.log(available)
                    this.$store.state.order.lineItems = this.$store.state.order.lineItems.filter(l => l.product !== uuid)
                }
            }
        },
        preOrderMode () {
            this.preOrder = true
            this.$store.state.order.timeType.timed = true
            this.$store.state.order.timeType.now = false
        },
        onItemChanged (event, currentItem, lastActiveItem) {
            console.log(event, currentItem, lastActiveItem)
        },
        showModalCart () {
            this.isModalCartVisible = true
        },
        closeModalCart () {
            this.isModalCartVisible = false
        },
        openSearch () {
            this.searchVal = ''
            if (this.isSearchVisable === true) {
                this.isSearchVisable = false
            } else {
                this.isSearchVisable = true
                console.log(this.$refs.search)
                setTimeout(() => {
                    this.$refs.search.focus()
                    this.$refs.menuscroll.scrollIntoView()
                }, 100)
            }
        },
        orderLocation (location) {
            this.location = location
        },
        glutenToggle () {
            if (this.stateGlutenFree) {
                this.stateGlutenFree = false
            } else {
                this.stateGlutenFree = true
            }
        },
        basicToggle () {
            if (this.stateBasic) {
                this.stateBasic = false
            } else {
                this.stateBasic = true
            }
        },
        addUnavailable (category) {
            if (!this.unavailableCategory.includes(category.uuid)) {
                this.unavailableCategory.push(category.uuid)
            }
        },
        removeUnavailable (category) {
            var removeIndex = this.unavailableCategory.findIndex(x => x === category.uuid)
            if (removeIndex !== -1) {
                this.unavailableCategory.splice(removeIndex, 1)
            }
        },
        slideBarLeft () {
            if (this.moreScroll > document.getElementById('navbar').scrollWidth - document.getElementById('navbar').clientWidth) {
                this.moreScroll = document.getElementById('navbar').scrollWidth - document.getElementById('navbar').clientWidth
            }
            var scrollMax = document.getElementById('navbar').clientWidth
            var scrollAmount = this.moreScroll - scrollMax
            document.getElementById('navbar').scrollTo({
                top: 0,
                left: scrollAmount,
                behavior: 'smooth'
            })
            this.moreScroll -= scrollMax
            if (this.moreScroll < 0) {
                this.moreScroll = 0
            }
        },
        slideBar () {
            if (this.moreScroll <= document.getElementById('navbar').scrollWidth - document.getElementById('navbar').clientWidth) {
                var scrollMax = document.getElementById('navbar').clientWidth
                var scrollAmount = this.moreScroll + scrollMax
                document.getElementById('navbar').scrollTo({
                    top: 0,
                    left: scrollAmount,
                    behavior: 'smooth'
                })
                this.moreScroll += scrollMax
            }
        }
    },
    watch: {
        location: {
            immediate: true,
            handler () {
                this.$store.state.order.location = (this.location || {}).uuid || (this.activeLocations.find(l => l.uuid === this.$route.query.location) || {}).uuid || (this.activeLocations[0] || {}).uuid
                this.$store.state.order.lineItems = this.$store.state.order.lineItems.filter(lineItem => this.catalog.products.find(p => p.uuid === lineItem.product).availability.locations.includes(this.$store.state.order.location)).map(o => o.toPlainObject())
            }
        },
        restaurant: {
            immediate: true,
            deep: true,
            handler (restaurant) {
                this.location = (this.location && this.location.uuid) ? this.location : ((this.activeLocations.find(l => l.uuid === this.$route.query.location)) || ((restaurant.locations || [{}])[0]))
            }
        },
        deliveryTime: {
            immediate: true,
            handler () {
                this.removeUnavailableFromCart()
            }
        }
        /*
        now: {
            handler () {
                if (this.$store.state.order.timeType.timed === this.now) {
                    this.$store.state.order.timeType.timed = !this.now
                }
                if (this.$store.state.order.timeType.now) {
                    this.unavailableCategory = []
                }
            },
            immediate: true
        },
        timed: {
            handler () {
                if (this.$store.state.order.timeType.now === this.timed) {
                    this.$store.state.order.timeType.now = !this.timed
                }
                if (this.$store.state.order.timeType.timed) {
                    this.timedOrder = true
                }
            },
            immediate: true
        } */
    },
    mounted () {
        this.now = true
    },
    created () {
        const updateScroll = (e) => {
            const currentCategory = (this.$refs.categories || []).filter(
                e => (e.$el && e.$el.getBoundingClientRect)
            ).map(
                e => [e.category.uuid, e.$el.getBoundingClientRect().y - 124]
            ).reduce(
                (acc, e) => (acc[0] === '' || (e[1] > acc[1] && e[1] < 0)) ? e : acc,
                ['', -100000]
            )
            this.currentCategory = currentCategory[0]
        }
        updateScroll()
        document.addEventListener('scroll', updateScroll)
    }
}
</script>
<style>
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}

.multiselect__tags {
        min-height: 40px;
        display: flex;
        padding-top: 15px;
        padding-bottom: 10px;
        padding-right: 80px;
        padding-left: 20px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
}
@media screen and (min-width: 1024px) {
    .mobile {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .mobile-small {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .web-mobile {
        display: none !important;
    }
}

.fade2-enter-active, .fade2-leave-active {
  transition: opacity .5s;
  transition-delay: 0.5s;
}
.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.food-search input {
    background: transparent;
    border-bottom-width: 3px;
}
.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
html {
    scroll-behavior: smooth;
}
.cart-icon span {
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}

.list-enter-active, .list-leave-active {
    transition: all 1s;
    }
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}

.list-leave-active {
    max-height: 136px;
}

.list-leave-to{
    max-height: 0;
}

.list-move {
    transition: transform 1s;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}

.grid1{
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;
}
</style>
