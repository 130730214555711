<template>
    <div>
        <div class="flex relative overflow-hidden rounded-xl p-5 py-8 md:px-10 md:py-16 mb-3 md:mb-5">
            <h3 v-if="available" class="text-white z-10 md:text-5xl text-2xl opacity-100 w-full"><span>{{category.title}}</span></h3>
            <h3 v-if="!available" :class="{'md:text-5xl': category.title.length < 19, 'md:text-4xl': category.title.length >= 19}" class="text-white z-10 text-2xl opacity-100 w-full"><span>{{category.title}}</span></h3>
            <h4 v-if="!available && !timed" class="text-white z-10 text-2xl">{{$t('restaurant.atm_not_available')}}</h4>
            <h4 v-if="!available && timed" class="text-white z-10 text-2xl">{{$t('restaurant.at_chosen_time_not_available')}}</h4>
            <div class="bg-gray-600 w-full h-full absolute left-0 top-0"></div>
            <div :class="{'opacity-30': !available, 'opacity-100': available}">
                <img class="left-0 top-0 absolute w-full h-full object-cover rounded-lg opacity-90" v-if="category.media[0]" :src="category.media[0].url"/>
            </div>
            <div style="position:absolute; top: 80%; left: 46%; right: 50%" v-show="(!available && !availability) || (!available && availability)" @click="$emit('showProducts')" class="cursor-pointer mb-2 text-white z-10 transform transition-transform" :class="{'rotate-180': !available && availability}">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            <div style="position:absolute; top: 93%; right: 1.5%" v-show="(category.media[0] || {}).origin === 'Unsplash'" class="mobile_author opacity-80 text-white text-xxs z-10 transform transition-transform">
                <a target="_blank" :href="(category.media[0] || {}).authorUrl">{{$t('restaurant.imgSource_Unsplash_author')}} {{(category.media[0] || {}).author}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { Category, RenderedAvailability } from 'delivery-boosting-2021-model'
export default {
    props: {
        category: {
            type: Category
        },
        availability: {
            type: Boolean
        },
        timed: {
            type: Boolean
        },
        deliveryTime: {
            type: Date
        }
    },
    components: {
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        available () {
            if (this.timed && this.deliveryTime) {
                return RenderedAvailability.checkAvailability(this.category.availability, new Date(this.deliveryTime)).available
            } else {
                return RenderedAvailability.checkAvailability(this.category.availability, new Date()).available
            }
        }
    }
}
</script>

<style>
.margjonus{
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .mobile_author {
        top: 84%!important;
    }
}
.text-xxs{
    font-size: 8px;
    line-height: 13px;
}
</style>
