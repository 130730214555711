<template>
  <div class="footer">
    <footer class="bg-gray-600 w-full" aria-labelledby="footerHeading">
      <h2 id="footerHeading" class="sr-only">Footer</h2>
      <div class="max-w-8xl mx-auto py-12 md:py-20 px-4 lg:px-8 flex flex-wrap">
        <div class="grid grid-cols-1 md:grid-cols-5 w-full">
          <div class="space-y-8 col-span-2">
            <div class="xl:mb-12">
              <p class="text-white text-base font-semibold">
                {{ $t("restaurant.powered_by") }}
              </p>
              <img
                class="h-12 w-auto m-1"
                src="@/assets/logo.svg"
                alt="Delivery Boosting"
              />
            </div>
            <div class="xl:mb-12">
              <p class="text-white text-base">
                {{ $t("restaurant.powered_by") }}
              </p>
              <img
                class="h-12 w-auto m-1"
                :src="(restaurant.generalMedia[0] || {}).url"
                alt="Delivery Boosting"
              />
            </div>
          </div>
          <div
            class="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 xl:mt-0 col-span-3"
          >
            <div>
              <h3 class="text-lg font-semibold text-white">
                Delivery Boosting
              </h3>
              <ul class="mt-4 space-y-6">
                <li>
                  <a
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.imprint") }}</a
                  >
                </li>
                <li>
                  <a
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.privacy") }}</a
                  >
                </li>
                <li>
                  <a
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.conditions") }}</a
                  >
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-white">
                {{ restaurant.headerTitle }}
              </h3>
              <ul class="mt-4 space-y-6">
                <li>
                  <a
                    :href="restaurant.footerImp"
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.imprint") }}</a
                  >
                </li>
                <li>
                  <a
                    :href="restaurant.footerDatasec"
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.privacy") }}</a
                  >
                </li>
                <li>
                  <a
                    :href="restaurant.footerAgb"
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ $t("restaurant.conditions") }}</a
                  >
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-white">
                {{ $t("restaurant.contact") }}
              </h3>
              <ul class="mt-4 space-y-6">
                <li>
                  <a
                    class="text-white text-base font-semibold hover:text-gray-900 cursor-none"
                  >
                    {{ restaurant.headerTitle }}
                  </a>
                </li>
                <li>
                  <a
                    :href="'tel:' + restaurant.footerTel"
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                    >{{ restaurant.footerTel }}</a
                  >
                </li>
                <li>
                  <a
                    :href="'mailto:' + restaurant.footerMail"
                    class="text-white text-base hover:text-gray-900 cursor-pointer"
                  >
                    {{ restaurant.footerMail }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="bg-gray-500" aria-labelledby="footerEnd">
      <div class="max-w-8xl mx-auto py-4 px-4 lg:px-8 text-center">
        <p class="text-xs text-white">
          &copy; 2021 Brand Boosting GmbH |
          {{ $t("restaurant.all_rights_reserved") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    }
}
</script>
