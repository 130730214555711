<template>
<div class="margjonus">
    <div :class="{'bg-gray-200': !available || !categoryAvailable}" class="flex items-center justify-between border md:border-0 bg-white p-5 md:p-10 rounded-lg">
        <div>
            <h4 h4 class="text-lg md:text-xl"><span v-if="product.no && product.no !== ''" class="text-gray-600">{{product.no}}.</span> {{product.title}} <span v-if="!product.custom" class="text-primary"> {{ $n(priceDefault/100, 'currency') }}</span></h4>
            <span class="mt-1 inline-block whitespace-pre-wrap">{{product.description}}</span>
        </div>
        <div v-if="(!categoryAvailable && !available) || (!categoryAvailable && available)">
            <span class="inline-flex items-center font-bold rounded-md text-gray-500">{{ $t('restaurant.not_available') }}<icon-plus @click.native="showCategoryTimes" :product="product" class="w-10 cursor-pointer"/></span>
        </div>
        <div v-if="categoryAvailable && !available">
            <span class="inline-flex items-center font-bold rounded-md text-gray-500">
                {{ nextAvailable }}
                <icon-plus @click.native="showTimes" :product="product" class="w-10 cursor-pointer"/>
            </span>
        </div>
        <div v-if="categoryAvailable && available">
            <button class="inline-flex items-center font-bold rounded-md text-gray-500 focus:outline-none" @click="showModal"><icon-plus class="w-10"/></button>
        </div>
        <!-- modal food config -->
        <transition name="slide-fade">
        <modal-not-available v-if="isTimesVisible" :product="product" @close="closeTimes" @jumpto="$emit('jumpto', $event); showModal()">
            <template #title>{{product.title}}</template>
            <template #description><div class="whitespace-pre-wrap">{{product.description}}</div></template>
        </modal-not-available>
        <modal-category-not-available v-if="isCategoryTimesVisible" :category="category" @close="closeCategoryTimes">
        <template #title>{{category.title}}</template>
        </modal-category-not-available>
        </transition>
        <show-additives v-if="isAdditiveVisible" @close="closeAdditives" :product="product"/>
        <transition name="slide-fade">
        <modal-food-to-cart v-if="isModalVisible" @close="closeModal" @add-to-cart="addToCart" @show-additives="showAdditives" :quantity.sync="quantity" :product="product">
            <template #title>{{product.title}}</template>
            <template #description ><div class="whitespace-pre-wrap">{{product.description}}</div></template>
            <template #filter>
                <div class="flex flex-wrap my-3">
                    <toggle @click.native="stateVegan = false" class="mr-5 my-2" v-model="stateVegetarian" ><span class="font-bold ml-4">{{$t('restaurant.vegetarian_button')}}</span></toggle>
                    <toggle @click.native="stateVegetarian = false" class="mr-5 my-2" v-model="stateVegan" ><span class="font-bold ml-4">{{$t('restaurant.vegan_button')}}</span></toggle>
                    <toggle @click.native="stateGlutenFree != stateGlutenFree" class="mr-5 my-2" v-model="stateGlutenFree" ><span class="font-bold ml-4">{{$t('restaurant.glutenfree_selections')}}</span></toggle>
                    <toggle @click.native="stateBasic != stateBasic" class="mr-5 my-2" v-model="stateBasic" ><span class="font-bold ml-4">{{$t('restaurant.basic_selections')}}</span></toggle>
                </div>
            </template>
            <template #price>{{ $n(renderedLineItem.price/100, 'currency') }}</template>
            <template #extra-dropdown>
                <div v-show="product.sizes.length > 1" class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 md:mb-0 md:p-3 relative">
                    <label class="block text-sm font-bold mb-1">{{$t('restaurant.size')}}</label>
                <div class="w-full" ref="test">
                    <div class="_fixed" :style="{x_width: `${$refs.test? $refs.test.clientWidth : '0'}px`}">
                    <multiselect
                    :value="tempLineItem.size"
                    @input="i => tempLineItem.size = i.uuid"
                    :options="product.sizes"
                    label="title"
                    track-by="uuid"
                    :placeholder="$t('restaurant.select_supplement')"
                    :deselectLabel="$t('restaurant.remove')"
                    :selectedLabel="$t('restaurant.selected')"
                    :selectLabel="$t('restaurant.select')">
                        <template slot="noResult">{{$t('restaurant.no_results')}}</template>
                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ product.sizes.find(s => s.uuid == option).title }} {{ product.custom? '' : `(${$n(product.sizes.find(s => s.uuid == option).getPriceByMethod($store.state.order.method.method).amount/100, 'currency')})`}} </strong></template>
                        <template slot="option" slot-scope="{ option }">{{option.title}} {{ product.custom? '' : `(${$n(option.getPriceByMethod($store.state.order.method.method).amount/100, 'currency')})`}}</template>
                    </multiselect>
                    </div>
                </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-4 md:mb-0 md:p-3" v-for="selection in product.selections" :key="selection.uuid">
                    <label class="block text-sm font-bold mb-1">{{selection.title}}</label>
                    <multiselect
                    :value="tempLineItem.selections[selection.uuid]"
                    @input="i => $set(tempLineItem.selections, selection.uuid, i.uuid)"
                    track-by="uuid"
                    label="title"
                    :options="selection.options.filter(u => (!stateVegetarian || u.properties.vegetarian || u.properties.vegan) && (!stateVegan || u.properties.vegan) && (!stateGlutenFree || u.properties.glutenFree) && (!stateBasic || u.properties.basic))"
                    :placeholder="$t('restaurant.select_supplement')"
                    :deselectLabel="$t('restaurant.remove')"
                    :selectedLabel="$t('restaurant.selected')"
                    :selectLabel="$t('restaurant.select')"
                    :preselectFirst="true"
                    >
                    <template slot="noResult">{{$t('restaurant.no_results')}}</template>
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ selection.options.find(s => s.uuid == option).title }} <span v-show="selection.options.find(s => s.uuid == option).getPriceByMethod($store.state.order.method.method).amount !== 0">(+{{ $n(selection.options.find(s => s.uuid == option).getPriceByMethod($store.state.order.method.method).amount/100, 'currency')}})</span></strong></template>
                    <template slot="option" slot-scope="{ option }">{{option.title}} <span v-show="option.getPriceByMethod($store.state.order.method.method).amount !== 0">(+ {{ $n(option.getPriceByMethod($store.state.order.method.method).amount/100, 'currency')}})</span></template>
                    </multiselect>
                </div>
            </template>
            <template #extra-select>
                <SmoothReflow v-if="upgrades.length > 0" :options="{transition: 'height 0.25s ease'}">
                    <transition-group name="extra-list-complete" tag="div" class="flex flex-wrap extra-button-select">>
                        <extra-button-select v-for="upgrade in upgrades.filter(u => (!stateVegetarian || u.properties.vegetarian || u.properties.vegan) && (!stateVegan || u.properties.vegan) && (!stateGlutenFree || u.properties.glutenFree) && (!stateBasic || u.properties.basic))" :value="tempLineItem.upgrades.includes(upgrade.uuid)" :key="upgrade.uuid" @input="u => u? tempLineItem.addUpgrade(upgrade.uuid) : tempLineItem.removeUpgrade(upgrade.uuid)">{{ upgrade.title }} <span v-show="upgrade.getPriceByMethod($store.state.order.method.method).amount !== 0">(+ {{ $n(upgrade.getPriceByMethod($store.state.order.method.method).amount/100, 'currency') }})</span></extra-button-select>
                    </transition-group>
                </SmoothReflow>
            </template>
        </modal-food-to-cart><!-- END: modal food config -->
        </transition>
    </div>
</div>
</template>

<script>
import IconPlus from '@/components/icons/IconPlus.vue'
import { LineItem, Product, RenderedLineItem, RenderedAvailability, Category } from 'delivery-boosting-2021-model'
import ModalFoodToCart from './ModalFoodToCart.vue'
import Multiselect from 'vue-multiselect'
import ExtraButtonSelect from './ExtraButtonSelect.vue'
import Toggle from '@/components/ui/Toggle.vue'
import ShowAdditives from '../../components/modals/ShowAdditives.vue'
import ModalNotAvailable from './ModalNotAvailable.vue'
import ModalCategoryNotAvailable from './ModalCategoryNotAvailable.vue'

export default {
    components: {
        IconPlus,
        ModalFoodToCart,
        Multiselect,
        ExtraButtonSelect,
        Toggle,
        ShowAdditives,
        ModalNotAvailable,
        ModalCategoryNotAvailable
    },
    props: {
        product: {
            type: Product
        },
        searchVal: {
            type: String
        },
        stateVegetarian: {
            type: Boolean
        },
        stateVegan: {
            type: Boolean
        },
        stateGlutenFree: {
            type: Boolean
        },
        stateBasic: {
            type: Boolean
        },
        category: {
            type: Category
        },
        timed: {
            type: Boolean
        },
        deliveryTime: {
            type: Date
        }
    },
    data () {
        return {
            isTimesVisible: false,
            isCategoryTimesVisible: false,
            valueExtra1: null,
            valueExtra2: null,
            isModalVisible: false,
            isAdditiveVisible: false,
            tempLineItem: new LineItem({ product: this.product.uuid, size: this.product.sizes[0].uuid })
        }
    },
    methods: {
        addToCart () {
            this.$store.state.order.addLineItem(JSON.parse(JSON.stringify({ ...this.tempLineItem.toPlainObject(), uuid: undefined })))
            this.closeModal()
        },
        showModal () {
            this.tempLineItem = new LineItem({ product: this.product.uuid, size: this.product.sizes[0].uuid })
            this.isModalVisible = true
        },
        closeModal () {
            this.isModalVisible = false
        },
        showTimes () {
            this.isTimesVisible = true
        },
        closeTimes () {
            this.isTimesVisible = false
        },
        showCategoryTimes () {
            this.isCategoryTimesVisible = true
        },
        closeCategoryTimes () {
            this.isCategoryTimesVisible = false
        },
        close () {
            this.$emit('close')
        },
        showAdditives () {
            this.isAdditiveVisible = true
        },
        closeAdditives () {
            this.isAdditiveVisible = false
        }
    },
    computed: {
        nextAvailable () {
            const days = this.product.availability.availabilityTimeRules.availableOn
            const day = new Date().getDay()

            const sortedDay = days.map(d => day > d ? d + 7 : d).sort((a, b) => a - b).filter(d => d >= day)[0] % 7
            const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

            const timeFrame = this.product.availability.availabilityTimeRules.timeFrames[0]

            return isNaN(sortedDay) || typeof timeFrame === 'undefined' ? this.$t('restaurant.not_available') : `verfügbar ${this.$t(`restaurant.short_${dayNames[sortedDay]}`)} ${timeFrame.from[0]}:${timeFrame.from[1]}`
        },
        available () {
            if (this.timed && this.deliveryTime) {
                return RenderedAvailability.checkAvailability(this.product.availability, new Date(this.deliveryTime)).available
            } else {
                return RenderedAvailability.checkAvailability(this.product.availability, new Date()).available
            }
        },
        categoryAvailable: {
            get () {
                if (this.timed && this.deliveryTime) {
                    return RenderedAvailability.checkAvailability(this.category.availability, new Date(this.deliveryTime)).available
                } else {
                    return RenderedAvailability.checkAvailability(this.category.availability, new Date()).available
                }
            }
        },
        renderedLineItem () {
            return new RenderedLineItem(this.tempLineItem, this.product, this.$store.state.order.method, this.$store.state.catalog)
        },
        quantity: {
            set (val) {
                this.tempLineItem.quantity = val
            },
            get () {
                return this.tempLineItem.quantity
            }
        },
        priceDefault () {
            if (this.$store.state.order.method.method === 'pick-up' && this.product.sizes[0] && this.product.sizes[0].getPriceByMethod('pick-up')) {
                return this.product.sizes[0].getPriceByMethod('pick-up').amount || 0
            } else if (this.$store.state.order.method.method === 'delivery' && this.product.sizes[0] && this.product.sizes[0].getPriceByMethod('delivery')) {
                return this.product.sizes[0].getPriceByMethod('delivery').amount || 0
            } else { return this.product.defaultPrice || 0 }
        },
        upgrades () {
            return this.$store.state.catalog.upgrades.filter(a => this.product.upgrades.includes(a.uuid))
        }
    }
}

</script>

<style>
.extra-item{
    transition: all 1s;
}

.extra-list-complete-enter, .extra-list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.extra-list-complete-leave-active {
  position: absolute;
}

.margjonus{
    margin-bottom: 20px;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}

</style>

<style src="@/assets/css/multiselect.css"></style>
