<template>
    <a :href="href" :class="settings" class="link inline-flex items-center relative font-bold focus:outline-none">
        <slot/>
    </a>
</template>

<script>
export default {
    name: 'ButtonL',
    props: ['href', 'settings']
}
</script>
<style scoped>
    .link.line:after {
        position: absolute;
        background-color: currentColor;
        width: 100%;
        content: '';
        left: 0;
        bottom: -3px;
        height: 3px;
    }
</style>
