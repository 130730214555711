<template>
    <div>
        <RemoteSection v-for="(section, i) in remoteSections" :key="i" :url="section.url" :app="section.app" :section="section.section" :restaurant="restaurant" :product="product" :category="category" :order="order" />
    </div>
</template>

<script>
import RemoteSection from './RemoteSection.vue'

// eslint-disable-next-line no-unused-vars
const app = {
    package: 'de.kevinsieger.copy',
    sections: [{
        name: 'copy-button',
        position: 'FOOD_DETAIL_BUTTON',
        filename: 'copy-button.vue'
    }],
    lambdas: [{
        name: 'copy-product',
        type: 'http',
        iam: ['RESTAURANT'],
        filename: 'copy-product.js'
    },
    {
        name: 'copy-product',
        type: 'call',
        iam: ['RESTAURANT'],
        filename: 'copy-product.js'
    }
    ],
    priceScripts: [
        {
            name: 'test',
            script: ''
        }
    ],
    settings: [{
        name: 'is-active',
        label: 'Aktivieren?',
        type: 'boolean',
        preset: true
    }]
}

export default {
    name: 'AppSection',
    components: {
        RemoteSection
    },
    props: ['position', 'product', 'category', 'order'],
    data () {
        return {
            remoteSections: []
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                const remoteSections = []
                const apps = this.restaurant.apps
                apps.forEach((app) => {
                    app.sections.filter(s => s.position === this.position).forEach(
                        (section) => {
                            remoteSections.push({
                                app,
                                section,
                                url: `restaurants/${this.restaurant.uuid}/apps/${app.package}/sections/${section.filename}`
                            })
                        }
                    )
                })
                this.remoteSections = remoteSections
            }
        }
    },
    computed: {
        restaurant () {
            // eslint-disable-next-line no-unused-expressions
            this.$store.state.restaurant.uuid
            return this.$store.state.restaurant
        }
    }
}
</script>

<style>
</style>
