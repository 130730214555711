<template>
    <div class="modal fixed z-75 inset-0" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div @click="close" class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" style="transform: translateY(-100px);" aria-hidden="true"></div>
        <div class="flex min-h-screen">
            <div class="inline-block align-bottom bg-white lg:rounded-t-lg text-left shadow-xl transform transition-all w-full mt-auto">
                <div class="max-w-8xl overflow-x-hidden overflow-y-auto mx-auto px-4 lg:px-8 flex lg:flex-wrap items-center pt-10 pb-10 lg:pb-10 h-screen lg:h-auto flex-col lg:flex-row">
                    <button class="hover:text-primary focus:outline-none ml-4 absolute right-5 top-5" type="button" @click="close" aria-label="Close modal">
                        <icon-x class="h-6"/>
                    </button>
                    <!-- modal content -->
                    <div class="w-full lg:w-auto flex flex-wrap items-center">
                        <div class="flex flex-col mr-4 md:mr-10 md:max-w-1/2">
                            <h3 class="text-2xl"><slot name="title"/></h3>
                            <span><slot name="description"/></span>
                        </div>
                        <!-- filter: optional
                        <slot name="filter"/> END: filter -->
                        <a v-if="product.additives.length != 0"  class="text-primary font-bold flex items-center my-2 cursor-pointer" @click="$emit('show-additives')"><icon-info class="h-8 mr-2"/>{{$t('restaurant.additives')}}</a>
                    </div>
                    <div class="w-full flex mt-auto lg:w-auto lg:mt-0 ml-auto order-last lg:order-none pt-4 lg:pt-0">
                        <input-quantity v-model="quantity1" class="mr-2 md:mr-5"/>
                        <button-b @click.native="$emit('add-to-cart')" class="to-cart bg-primary lg px-5 md:px-12 justify-center">{{$t('restaurant.add_to_cart')}} (<slot name="price"/>)</button-b>
                    </div><!-- END: basic content -->
                    <div class="w-full flex flex-wrap items-center mt-2">
                        <slot name="filter"/><!-- END: filter -->
                    </div>
                    <div class="w-full overflow-visible mt-4 lg:max-h-96">
                        <!-- select: Beilagen, Soße,... / optional -->
                        <div v-show="product.sizes.length > 1 || product.selections.length > 0" class="mb-10 w-full">
                            <h5 class="mb-2">{{$t('restaurant.select')}}</h5>
                            <div class="flex flex-wrap md:-mx-3">
                                <slot name="extra-dropdown"/>
                            </div>
                        </div><!-- END select -->
                        <!-- select: Extras / optional -->
                        <div v-if="!!this.$slots['extra-select']" class="mb-10 w-full">
                            <h5 class="mb-2">{{$t('restaurant.extras')}}</h5>
                                <slot name="extra-select"/>
                        </div><!-- END select -->
                    </div>
                    <!-- END: modal content -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconInfo from '@/components/icons/IconInfo.vue'
import IconX from '@/components/icons/IconX.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import InputQuantity from '@/components/ui/InputQuantity.vue'
import { Product } from 'delivery-boosting-2021-model'

export default {
    components: { IconX, IconInfo, ButtonB, InputQuantity },
    name: 'Modal',
    data () {
        return {
            quan: this.quantity
        }
    },
    props: {
        quantity: {
            type: Number
        },
        product: {
            type: Product
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        keyUp (event) {
            if (event.keyCode === 27) {
                this.close()
            }
        }
    },
    computed: {
        quantity1: {
            get () {
                return this.quan
            },
            set (val) {
                this.quan = val
                this.$emit('update:quantity', this.quan)
            }
        }
    },
    created: function () {
        document.addEventListener('keyup', this.keyUp)
    },
    destroyed: function () {
        document.removeEventListener('keyup', this.keyUp)
    }
}
</script>

<style lang="postcss">
    @media screen and (max-width: 1023px) {
        .to-cart.btn.lg {
            @apply px-2 w-full
        }
    }
</style>
