/* eslint-disable vue/no-async-in-computed-properties */
<template>
    <div class="body min-h-screen">
        <restaurant-checkout-header :locationUuid="order.location" :newTab="true"/>
        <div class="body md:bg-gray-100">
            <div class="max-w-8xl mx-auto p-6 md:p-12 pt-10 2xl:px-2">
                <div class="flex flex-wrap">
                    <!-- checkout progress -->
                    <div class="hidden lg:block w-2/7 pr-10">
                        <div class="flex flex-col sticky z-50" style="top:3rem">
                            <div class="flex flex-col flex-grow pb-4">
                                <div class="flex-grow flex flex-col">
                                    <nav class="flex-1 space-y-6">
                                        <nav-card>
                                            <template #icon>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                            </template>
                                            <template #title>{{$t('restaurant.details')}}</template>
                                            <template #number>
                                                <div class="text-white">
                                                <svg style="color: white" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                </svg>
                                                </div>
                                            </template>
                                        </nav-card>
                                        <nav-card>
                                            <template #icon>
                                                <icon-credit-card/>
                                            </template>
                                            <template #title>{{$t('restaurant.payment')}}</template>
                                            <template #number>
                                                <div class="text-white">
                                                <svg style="color: white" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                </svg>
                                                </div>
                                            </template>
                                        </nav-card>
                                        <nav-card state="active">
                                            <template #icon>
                                                <svg style="text-green" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                                    <path opacity=".3" d="M4 13v1h4v-3H6c-1.1 0-2 .9-2 2z" fill="currentColor"></path><path d="M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7zM7 17c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1zm1-3H4v-1c0-1.1.9-2 2-2h2v3z" fill="currentColor"></path><path d="M5 6h5v2H5zm14 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z" fill="currentColor"></path>
                                                </svg>
                                            </template>
                                            <template #title>{{$t('restaurant.delivery')}}</template>
                                            <template #number>3</template>
                                        </nav-card>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div><!-- END: checkout progress -->
                    <!-- content -->
                    <div class="w-full lg:w-5/7">
                        <main class="grid grid-cols-1 gap-7 focus:outline-none" tabindex="0">
                            <card-box>
                                <div class="flex flex-wrap justify-between">
                                    <div class="flex flex-wrap mb-5">
                                        <span class="mr-2">{{$t('restaurant.delivery_num')}}:</span><badge settings="bg-gray-600 transparent">{{ order.name }}</badge>
                                    </div>
                                    <div class="flex flex-wrap mb-5">
                                        <span class="mr-2">{{$t('restaurant.status')}}:</span><badge settings="bg-green transparent">{{order.state}}</badge>
                                    </div>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <div class="w-full md:w-1/2 mt-10">
                                        <img src="@/assets/food-delivery.svg"/>
                                    </div>
                                    <div class="w-full md:w-1/2 md:pl-10 mt-10">
                                        <h1 class="text-2xl md:text-3xl lg:text-4xl xl:text-6xl mb-3 md:mb-6">{{$t('restaurant.order_')}} <span class="text-green">{{$t('restaurant.sucessful')}}</span> {{$t('restaurant.ordered')}}!</h1>
                                        <p class="max-w-xs mb-5 md:mb-10 md:text-lg">{{$t('restaurant.ordered_message')}}</p>
                                        <button-a href="#jumpmark" class="bg-primary transparent">{{$t('restaurant.watch_order')}}</button-a>
                                    </div>
                                </div>
                                <div class="flex flex-col mt-10">
                                    <div v-chat-scroll="{enable: true, always: true, smooth: true}" style="max-height: 600px" class="relative flex-1 overflow-y-auto mb-10">
                                        <div v-for="message in renderedMessageList" :key="message.id">
                                            <chat-left v-if="message.type === 'admin'">
                                                <template #message>
                                                    {{message.text}}
                                                </template>
                                                <template #time>
                                                    {{message.createdAt.toDate().getHours()}}:{{(message.createdAt.toDate().getMinutes()).toString().padStart(2, '0')}}
                                                </template>
                                            </chat-left>
                                            <chat-right class="mr-4" v-if="message.type === 'user'">
                                                <template #message>
                                                    {{message.text}}
                                                </template>
                                                <template #time>
                                                    {{message.createdAt.toDate().getHours()}}:{{(message.createdAt.toDate().getMinutes()).toString().padStart(2, '0')}}
                                                </template>
                                            </chat-right>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="flex-shrink-0 mt-5 flex">
                                        <div class="flex-grow mr-5 md:pr-10">
                                            <input type="text" @keyup.enter="addMessage" v-model="chatMessage" id="about" name="about" resize="none" rows="2" class="block w-full border-0 hover:border-0 focus:border-0 focus:ring-0 py-3.5 px-0" style="resize: none;" :placeholder="$t('restaurant.chat_placeholder')">
                                        </div>
                                        <div class="flex-shrink">
                                            <button-b @click.native="addMessage" class="bg-primary">{{ $t('dashboard.send') }}</button-b>
                                        </div>
                                    </div>
                                </div>
                            </card-box>
                            <card-box id="jumpmark">
                                <h3 class="mb-10 mt-5 md:mt-0">{{$t('restaurant.final_order')}}</h3>
                                <div class="grid grid-cols-1">
                                    <overview-product v-for="renderedLineItem in renderedOrders.lineItems" :key="renderedLineItem.uuid">
                                        <template #amount>
                                            {{renderedLineItem.quantity}} x
                                        </template>
                                        <template #mainproduct>
                                            {{ renderedLineItem.product.title }}
                                        </template>
                                        <template #size v-if="renderedLineItem.product.sizes.length > 1">
                                            <badge settings="bg-primary transparent">{{renderedLineItem.size.title}}</badge>
                                        </template>
                                        <template #addons>
                                            <li v-for="selection in renderedLineItem.selections" :key="selection.uuid"><span class="text-primary"><strong>{{selection.selection.title}}:</strong></span> {{selection.option.title}} </li>
                                            <li v-for="upgrade in renderedLineItem.upgrades" :key="upgrade.uuid"><badge settings="bg-gray-600 transparent">
                                        {{ upgrade.title }}
                                    </badge> </li>
                                        </template>
                                        <template #price>
                                            {{ $n(renderedLineItem.price/100, 'currency') }}
                                        </template>
                                    </overview-product>
                                    <div v-if="order.discounts.length > 0">
                                        <overview-product v-show="($store.state.catalog.getMethodByUuid(order.method.method)|| {}).priceAmount !== 0">
                                            <template #mainproduct>
                                                {{ 'Rabatt' }}
                                            </template>
                                            <template #price>
                                                <span class="text-green">{{ $n(renderedOrders.newDiscountTotal/100, 'currency')}}</span>
                                            </template>
                                        </overview-product>
                                    </div>
                                    <div v-if="!maxValueReached">
                                        <overview-product v-show="($store.state.catalog.getMethodByUuid(order.method.method)|| {}).priceAmount !== 0">
                                            <template #mainproduct>
                                                {{ $t(`restaurant.surcharge_${order.method.method}`) }}
                                            </template>
                                            <template #price>
                                                {{ $n(($store.state.catalog.getMethodByUuid(order.method.method) || {}).priceAmount/100, 'currency')}}
                                            </template>
                                        </overview-product>
                                    </div>
                                    <div class="flex p-1 pl-0 border-b-2 border-gray-200"/>
                                    <overview-product v-show="($store.state.catalog.getMethodByUuid(order.method.method)|| {}).priceAmount !== 0">
                                        <template #mainproduct>
                                            {{ $t('restaurant.rendered_price') }}
                                        </template>
                                        <template #price>
                                            {{ $n(renderedOrders.orderPrice/100, 'currency') }}
                                        </template>
                                    </overview-product>
                                </div>
                            </card-box>
                        </main>
                    </div><!-- END: content -->
                </div>
            </div>
        </div>
        <restaurant-footer/>
    </div>
</template>

<script>
import RestaurantFooter from '@/components/layout/RestaurantFooter.vue'
import NavCard from '@/components/restaurant/NavCard.vue'
import RestaurantCheckoutHeader from '@/components/layout/RestaurantCheckoutHeader.vue'
import IconCreditCard from '@/components/icons/IconCreditCard.vue'
import CardBox from '@/components/dashboard/CardBox.vue'
import Badge from '@/components/ui/Badge.vue'
import ButtonB from '@/components/ui/ButtonB.vue'
import ButtonA from '@/components/ui/ButtonA.vue'
import ChatLeft from '@/components/ui/ChatLeft.vue'
import ChatRight from '@/components/ui/ChatRight.vue'
import OverviewProduct from '../../components/restaurant/OverviewProduct.vue'
import { db, FieldValue, messaging } from '@/firebase'
import { RenderedOrder, Message } from 'delivery-boosting-2021-model'

export default {
    name: 'OrderComplete',
    async created () {
        const token = await messaging.getToken({ vapidKey: 'BICklAKhvPWx8zTtzK-eOTq4xceV-4QTYi5_xGRYiNLIaqbtNuYUXBXKSY6pA0EBwQgnFGHM1-0FKPCgj48NiiA' })
        if (token) {
            console.log('token: ', token)
            await this.tokenCollection.doc(token).set({})
        } else {
            console.log('No registration token available. Request permission to generate one.')
        }
        messaging.onMessage(function (payload) {
            const content = {
                title: payload.notification.title,
                body: payload.notification.body,
                icon: payload.data.icon
            }
            console.log(content)
        })
    },
    data () {
        return {
            isModalVisible: false,
            value: null,
            chatMessage: '',
            order: {},
            messageList: {
                messages: []
            }
        }
    },
    components: {
        RestaurantFooter,
        NavCard,
        RestaurantCheckoutHeader,
        IconCreditCard,
        Badge,
        ButtonB,
        ButtonA,
        ChatLeft,
        ChatRight,
        CardBox,
        OverviewProduct
    },
    computed: {
        restaurantId () {
            return this.$route.params.restaurantId
        },
        orderUuid () {
            return this.$route.params.orderId
        },
        tokenCollection () {
            return db.doc(`restaurants/${this.restaurantId}/orders/${this.orderUuid}`).collection('tokens')
        },
        restaurant () {
            return this.$store.state.restaurant
        },
        catalog () {
            return this.$store.state.catalog
        },
        renderedOrders () {
            return new RenderedOrder(this.order, this.$store.state.catalog)
        },
        delivery_maxValue () {
            return (this.catalog.getMethodByUuid('delivery') || {}).maxValue
        },
        pickup_maxValue () {
            return (this.catalog.getMethodByUuid('pick-up') || {}).maxValue
        },
        maxValueReached () {
            var sum = 0
            const method = this.renderedOrders.method.method
            for (const l of this.renderedOrders.lineItems) {
                sum += l.price
            }
            if (method === 'delivery') {
                if (sum < this.delivery_maxValue) {
                    return false
                }
            } else if (method === 'pick-up') {
                if (sum < this.pickup_maxValue) {
                    return false
                }
            }
            return true
        },
        renderedMessageList () {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.messageList.messages.sort(function (x, y) { return x.createdAt - y.createdAt })
        }
    },
    methods: {
        async addMessage () {
            if (this.chatMessage !== '') {
                await this.messageList.addMessage(new Message({
                    type: 'user',
                    text: this.chatMessage,
                    createdAt: FieldValue.serverTimestamp(),
                    automated: false
                }))
                this.chatMessage = ''
            }
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            async handler () {
                try {
                    this.messageList = await this.$store.state.restaurant.getMessageList(this.orderUuid)
                    this.order = await this.$store.state.restaurant.getOrder(this.orderUuid)
                } catch (error) {
                    console.warn(error)
                }
            }
        }
    }
}
</script>

<style src="@/assets/css/multiselect.css"></style>
