<template>
    <modal @close="close" class="z-100">
        <template #title>{{ $t('restaurant.additives_in') }} {{ product.title }}:</template>
        <template #body>
            <div class="flex flex-wrap -mx-2">
                <div class="px-2 py-2" v-for="uuid in product.additives" :key="uuid">
                    <div class="border p-4 rounded-lg">
                        <strong class="text-black">{{ $store.state.catalog.getAdditiveByUuid(uuid).title }}</strong><span class="text-black font-normal" v-if="$store.state.catalog.getAdditiveByUuid(uuid).description">: {{ $store.state.catalog.getAdditiveByUuid(uuid).description }}</span>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="p-4 md:p-10">
                <button-b @click.native="close" class="bg-primary">{{$t('dashboard.okay')}}</button-b>
            </div>
        </template>
    </modal>
</template>

<script>
import ButtonB from '../../components/ui/ButtonB.vue'
import Modal from '../../components/dashboard/Modal.vue'
import { Product } from 'delivery-boosting-2021-model'

export default {
    name: 'DashboardSettings',
    data () {
        return {
            valueStatus: null
        }
    },
    props: {
        product: {
            type: Product
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        async confirmDeletion () {
            this.$store.state.catalog.removeCategory(this.category.uuid)
            await this.$store.state.catalog.commit()
            this.close()
        }
    },
    components: {
        ButtonB,
        Modal

    }
}
</script>
