import { Liquid } from 'liquidjs'

const engine = new Liquid()
engine.registerFilter('money',
    number => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number / 100)
)

/**
 * Liquid Engine with added Filter support
 * @type {Liquid}
 */
export const liquid = engine
