<template>
    <div class="modal fixed z-75 inset-0 overflow-hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" style="transform: translateY(-100px); pointer-events: none;" aria-hidden="true"></div>
        <div class="flex min-h-screen">
            <div class="inline-block align-bottom bg-white text-left shadow-xl transform transition-all w-full mt-auto h-screen">
                <div class="h-full flex flex-col">
                    <button class="hover:text-primary focus:outline-none ml-4 absolute right-5 top-5" type="button" @click="close" aria-label="Close modal">
                        <icon-x class="h-6"/>
                    </button>
                    <!-- modal content -->
                    <div class="mt-10 w-full p-4">
                        <slot name="head"/>
                    </div>
                    <div class="p-4 overflow-y-auto">
                        <slot name="items"/>
                    </div>
                    <div class="border-t rounded-t-lg px-4 pt-4 pb-2 bg-white w-full z-50 lg:hidden mt-auto">
                        <div class="flex justify-center flex-col">
                            <slot name="checkout"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconX from '@/components/icons/IconX.vue'
export default {
    components: { IconX },
    name: 'Modal',
    props: {
        quantity: {
            type: Number
        }
    },
    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
