<template>
    <nav class="bg-white shadow">
        <div class="max-w-8xl mx-auto px-4 lg:px-8">
            <div class="relative flex justify-between items-center h-24">
                <div class="flex-1 flex items-center sm:items-stretch">
                    <div class="flex-shrink flex items-center max-h-14 max-w-sm pr-10">
                        <img class="block altImage h-14" v-if="this.$store.state.restaurant.generalMedia && this.$store.state.restaurant.generalMedia[0]" :src='this.$store.state.restaurant.generalMedia[0].url' :alt="this.$store.state.restaurant.generalTile">
                        <img class="block h-14 w-auto altImage" v-else src='@/assets/logo.svg' :alt="this.$store.state.restaurant.generalTile">
                    </div>
                </div>
                <div class="items-center mr-16 hidden md:flex">
                    <div class="flex items-center mr-16">
                        <div v-if="activeLocations.length > 1" class="web flex items-center">
                            <div class="flex flex-col justify-center mr-3">
                                <span class="text-sm font-bold text-gray-600 min-h-2">{{$t('restaurant.location')}}</span>
                                <span class="text-sm font-bold text-gray-600 ">{{$t('restaurant.chose')}}</span>
                            </div>
                            <multiselect
                                :options="activeLocations"
                                v-model="location"
                                :track-by="trackBy"
                                label="title"
                                :deselectLabel="$t('dashboard.remove')"
                                :selectedLabel="$t('dashboard.selected')"
                                :selectLabel="$t('dashboard.select')"
                                :multiple="false"
                                :placeholder="'Ort auswählen'"
                                >
                                <template slot="noResult">{{ $t('dashboard.no_results') }}</template>
                                <template slot="option" slot-scope="{ option }">{{ option.generalTitle }}</template>
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.generalTitle }}</strong></template>
                                </multiselect>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-primary mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <div class="flex flex-col">
                            <span class="text-sm font-bold text-gray-600">{{(location || {}).generalZip}} {{(location || {}).generalCity}}</span>
                            <span>{{(location || {}).generalStreet}} {{(location || {}).generalStreetnum}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-primary mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div class="flex flex-col">
                            <span class="text-sm font-bold text-gray-600">{{ $t('restaurant.waiting_period') }}</span>
                            <span>{{this.$store.state.realtimedata.waitingTime}} {{ $t('restaurant.min') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    data () {
        return {
            location: {},
            trackBy: 'uuid'
        }
    },
    props: {
        location1: {
            type: Object
        }
    },
    watch: {
        restaurant: {
            immediate: true,
            handler () {
                this.location = this.activeLocations.find(l => l.uuid === this.$route.query.location) || this.activeLocations[0]
            }
        },
        location: {
            immediate: true,
            handler () {
                this.$emit('location', this.location)
            }
        },
        location1: {
            immediate: true,
            handler () {
                this.location = this.location1
            }
        }
    },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        },
        activeLocations () {
            return this.restaurant.locations.filter(l => l.active === true)
        }
    }
}
</script>

<style>
@media screen and (max-width: 1024px) {
    .web {
        display: none !important;
    }
}
.multiselect__tags {
        min-height: 40px;
        display: flex;
        padding-top: 10px;
        padding-bottom: 5px;
        padding-right: 80px;
        padding-left: 20px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
        }
</style>
