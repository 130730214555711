<template>
    <div class="relative bg-gray-600">
        <div class="absolute inset-0">
            <img v-if="restaurant.headerMedia[0]" class="w-full h-full object-cover opacity-80" :src="(restaurant.headerMedia[0] || {}).url">
            <img v-else class="w-full h-full object-cover">
            <div class="absolute inset-0 bg-gray-600 opacity-40" style="mix-blend-mode: multiply;" aria-hidden="true"></div>
        </div>
        <div class="relative max-w-8xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 class="text-3xl md:text-5xl font-extrabold tracking-tight text-white text-center mx-auto"><span>{{restaurant.headerTitle}}</span></h1>
            <p class="mt-6 text-base md:text-xl text-white max-w-3xl text-center mx-auto"><span>{{restaurant.headerDescription}}</span></p>
            <span v-show="(restaurant.headerMedia[0] || {}).origin === 'Unsplash'" style="position:absolute; top: 95%; left: 80%;" class="mb-3 opacity-80 text-gray-500 text-xs z-10">
                    <a target="_blank" :href="(restaurant.headerMedia[0] ||{}).authorUrl"><p>{{$t('restaurant.imgSource_Unsplash_author')}} {{(restaurant.headerMedia[0] || {}).author}}</p></a>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    }
}
</script>

<style>

</style>
