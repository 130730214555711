<template>
    <div class="flex items-center justify-center header h-24 rounded-lg border-b">
        <div class="max-w-8xl mx-auto px-4 lg:px-8 flex items-center py-8">
            <div class="mr-8">
                <button-l @click.native="routerBack" settings="line cursor-pointer">{{ $t('restaurant.back') }}</button-l>
            </div>
            <div class="flex justify-center flex-shrink-0">
                <img class="h-14 w-auto" src="@/assets/logo.svg" alt="Workflow">
            </div>
        </div>
    </div>
</template>

<script>
import ButtonL from '../ui/ButtonL.vue'
export default {
    components: { ButtonL },
    computed: {
        restaurant () {
            return this.$store.state.restaurant
        }
    },
    methods: {
        routerBack () {
            if (this.newTab) {
                const routeData = this.$router.resolve({ name: 'restaurant', params: { restaurantId: this.restaurant.uuid } })
                window.open(routeData.href, '_blank')
            } else {
                this.$router.push({ name: 'restaurant', params: { restaurantId: this.restaurant.uuid } })
            }
        }
    },
    props: {
        newTab: {
            Type: Boolean
        }
    }
}
</script>

<style>

</style>
