<template>
    <span class="badge inline-flex items-center px-3 py-0.5 rounded-full text-sm font-bold" :class="settings">
        <slot/>
    </span>
</template>

<script>
export default {
    name: 'Badge',
    props: ['settings']
}
</script>
