import { Catalog, Restaurant, DataBaseConnection, Order, RenderedOrder, RealTimeData } from 'delivery-boosting-2021-model'
import { firestore } from '@/firebase'
import router from '../router'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const placeHolderSnap = { ref: { id: '__loading__', onSnapshot: () => '', set: () => '', collection: () => '' } }

const dbc = new DataBaseConnection(firestore)

export default new Vuex.Store({
    state: {
        restaurant: new Restaurant(placeHolderSnap),
        catalog: new Catalog(placeHolderSnap),
        realtimedata: new RealTimeData(placeHolderSnap),
        order: new Order({ method: { method: 'pick-up' } })
    },
    mutations: {
        SET_RESTAURANT (state, restaurant) {
            state.restaurant = restaurant
        },
        SET_CATALOG (state, catalog) {
            state.catalog = catalog
        },
        SET_REALTIMEDATA (state, realtimedata) {
            state.realtimedata = realtimedata
        }
    },
    actions: {
        async fetchRestaurant ({ commit, state }) {
            if (router.currentRoute.params.restaurantId) {
                commit('SET_RESTAURANT', await dbc.getRestaurant(router.currentRoute.params.restaurantId))
                commit('SET_REALTIMEDATA', new RealTimeData(await state.restaurant._ref.collection('data').doc('realtimedata').get()))
                commit('SET_CATALOG', await state.restaurant.getCatalog())
            }
        }
    },
    getters: {
        renderedOrder (state) {
            return new RenderedOrder(state.order, state.catalog)
        }
    },
    modules: {
    }
})
