import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import UI from '@/pages/UI.vue'
import About from '@/views/About.vue'
import Checkout from '@/pages/restaurant/Checkout.vue'
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Restaurant from '@/pages/restaurant/Restaurant.vue'
import OrderComplete from '@/pages/restaurant/OrderComplete.vue'

const Overview = () => import('../pages/dashboard/Overview.vue')
const FoodDetail = () => import('../pages/dashboard/FoodDetail.vue')
const FoodList = () => import('../pages/dashboard/FoodList.vue')
const Menu = () => import('../pages/dashboard/Menu.vue')
const Settings = () => import('../pages/dashboard/Settings.vue')
const Chat = () => import('../pages/dashboard/Chat.vue')
const Login = () => import('../pages/dashboard/Login.vue')

Vue.use(VueRouter)
Vue.use(Datetime)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/ui',
        name: 'UI',
        component: UI
    },
    {
        path: '/dashboard/:restaurantId',
        name: 'dashboard-overview',
        component: Overview,
        props: true
    },
    {
        path: '/dashboard/:restaurantId/order/:orderId',
        name: 'dashboard-chat',
        component: Chat,
        props: true
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/login/:fallback',
        name: 'login-fallback',
        component: Login
    },
    {
        path: '/dashboard/:restaurantId/settings',
        name: 'dashboard-settings',
        component: Settings
    },
    {
        path: '/dashboard/:restaurantId/menu',
        name: 'dashboard-menu',
        component: Menu
    },
    {
        path: '/dashboard/:restaurantId/category/:categoryId',
        name: 'dashboard-food-list',
        component: FoodList
    },
    {
        path: '/dashboard/:restaurantId/products/:productId',
        name: 'dashboard-food-detail',
        component: FoodDetail
    },
    {
        path: '/restaurant/:restaurantId/checkout',
        name: 'order-checkout',
        component: Checkout,
        props: true
    },
    {
        path: '/restaurant/:restaurantId',
        name: 'restaurant',
        component: Restaurant,
        props: true
    },
    {
        path: '/restaurant/:restaurantId/order-complete/:orderId',
        name: 'order-complete',
        component: OrderComplete,
        props: true
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    linkExactActiveClass: 'active'
})

export default router
